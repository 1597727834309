<template>
  <div class="question-builder">
    <div class="question-builder__content input-group">
      <label for="" v-html="langObj['s4-customQuestions'].modalLabel1"></label>
      <a-textarea
        v-model.trim="questionData.questionText"
        :auto-size="{ minRows: type === 'essay' ? 8 : 2 }"
        :key="type"
      />
    </div>
    <div class="question-builder__cta">
      <span class="question-builder__cta-info">{{ successInfoMessage }}</span>
      <div>
        <a-tooltip
          placement="top"
          overlayClassName="ant-tooltip--small"
          :visible="cancelTooltip"
        >
          <template slot="title">
            <span
              v-html="langObj['s4-customQuestions'].modalCancelTooltip"
            ></span>
          </template>
          <a-button
            @mouseenter="setCancelTooltip"
            @mouseleave="cancelTooltip = false"
            @click="$emit('question-builde:close')"
            v-html="langObj['s4-customQuestions'].modalButton1"
          >
          </a-button>
        </a-tooltip>
        <a-button
          type="primary"
          @click="saveData"
          :disabled="
            questionData.questionText === null ||
            questionData.questionText === ''
          "
          v-html="langObj['s4-customQuestions'].modalButton2"
        >
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { store, mutations } from "../questions/store.js";

export default {
  name: "TextTypeBuilder",
  props: {
    editMode: {
      type: Boolean,
    },
    questionInfo: {
      type: Object,
    },
    questionIndex: {
      type: Number,
    },
    langObj: {
      type: Object,
    },
    type: {
      type: String,
      default() {
        return "text";
      },
    },
  },
  components: {},
  data() {
    return {
      successInfoMessage: null,
      cancelTooltip: false,
      questionData: {
        questionType: null,
        questionText: null,
        subQuestions: [],
        options: [],
        sortOption: "",
      },
    };
  },

  methods: {
    saveData() {
      this.successInfoMessage = this.langObj["s4-customQuestions"].modalSave1;
      this.$emit("question-data", this.questionData, this.questionIndex);
      this.questionData = {
        questionType: null,
        questionText: null,
        subQuestions: [],
        options: [],
        sortOption: "",
      };
      setTimeout(() => {
        this.successInfoMessage = this.langObj["s4-customQuestions"].modalSave2;
      }, 500);
      setTimeout(() => {
        this.successInfoMessage = null;
      }, 1000);
    },
    setCancelTooltip() {
      if (
        this.questionData.questionText !== null &&
        this.questionData.questionText !== "" &&
        this.editMode === false
      ) {
        this.cancelTooltip = true;
        setTimeout(() => {
          this.cancelTooltip = false;
        }, 2000);
      } else {
        this.cancelTooltip = false;
      }
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal) {
        this.questionData = {
          questionType: newVal,
          questionText: null,
          subQuestions: [],
          options: [],
          sortOption: "",
        };
      },
    },
  },
  mounted() {
    if (this.editMode) {
      this.questionData = this.questionInfo.CustomQuestions[this.questionIndex];
    }
  },
};
</script>
