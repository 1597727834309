<template>
  <div class="authoring-wrapper">
    <div class="flex h-full">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="stepsType"
        :conciergeAssistance="
          stepsType === 'map-maker' || stepsType === 'brand-fingerprint'
            ? false
            : true
        "
      />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
            <a-breadcrumb-item
              ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
            >
            <a-breadcrumb-item
              ><a href="/products">Products</a></a-breadcrumb-item
            >
            <a-breadcrumb-item>{{ breadcrumbTitle }}</a-breadcrumb-item>
          </template>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div class="page-title">
            <icon-base
              :width="32"
              :height="33"
              :viewBox1="32"
              :viewBox2="33"
              iconName="adjustments"
              iconColor="transparent"
              ><icon-adjustments
            /></icon-base>
            <div class="page-title__text">
              <h1>General Settings</h1>
              <span>General Study information</span>
            </div>
          </div>
          <div class="box-wrapper box-wrapper--settings-form">
            <p class="font-size-14 mb-20">*All fields are required.</p>
            <div class="box-wrapper__inner">
              <div class="input-group">
                <label>
                  <span v-html="langObj['s1-studyname'].quiz"></span>
                  <a-tooltip>
                    <template slot="title">
                      <span v-html="langObj['s1-studyname'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>

                <div class="position-r">
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-input
                    :placeholder="getNamePlaceholder()"
                    v-model="surveyName"
                  />
                </div>
              </div>

              <div class="input-group" v-if="showBrandName">
                <label>
                  <span v-html="langObj['s1-brand'].quiz"></span>
                  <a-tooltip>
                    <template slot="title">
                      <span v-html="langObj['s1-brand'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>

                <div class="position-r">
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-input placeholder="eg: AAA" v-model="brandName" />
                </div>
              </div>

              <div class="input-group" v-if="showTimeframe">
                <label>
                  <span v-html="langObj['s1-timeframe'].quiz"></span>
                  <a-tooltip>
                    <template slot="title">
                      <span v-html="langObj['s1-timeframe'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>

                <div class="position-r">
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-input placeholder="eg: AAA" v-model="timeframe" />
                </div>
              </div>

              <div class="input-group">
                <label>
                  <span
                    v-html="langObj['s1-packtest-country'].quiz"
                    v-if="
                      stepsType !== 'map-maker' &&
                      stepsType !== 'brand-fingerprint'
                    "
                  ></span>
                  <span
                    v-html="langObj['s1-packtest-country'].quizStudy"
                    v-else
                  ></span>
                  <a-tooltip>
                    <template slot="title">
                      <span v-html="langObj['s1-packtest-country'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>

                <div class="country position-r">
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-select
                    class="fullWidth"
                    placeholder="Please select country"
                    option-filter-prop="children"
                    show-search
                    :filter-option="filterCountry"
                    :key="selectDropdownsKey"
                    :open="openCountry"
                    :default-value="
                      editableSurvey
                        ? editableSurvey.SurveyConfig.Country
                        : undefined
                    "
                    @change="selectCountry"
                    @focus="openDropdown"
                    @blur="openCountry = false"
                    @select="openCountry = false"
                  >
                    <a-select-option
                      v-for="market in markets"
                      :key="market.MarketId"
                      :value="market.MarketId"
                    >
                      {{ market.MarketName }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>

              <div class="input-group">
                <label>
                  <span v-html="langObj['s1-language'].quiz"></span>
                  <a-tooltip>
                    <template slot="title">
                      <span v-html="langObj['s1-language'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>

                <div class="position-r">
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-select
                    @change="selectLang"
                    class="fullWidth"
                    show-search
                    placeholder="Please select country first to get related language list"
                    :default-value="
                      langs[langIndex]
                        ? langs[langIndex].LucidLanguageName
                        : undefined
                    "
                    :key="Math.random()"
                  >
                    <a-select-option
                      v-for="(lang, index) in langs"
                      :key="index"
                      :value="index"
                    >
                      {{ lang.LucidLanguageName }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>

              <div class="input-group">
                <label>
                  <span
                    v-html="langObj['s1-packtest-category'].quiz"
                    v-if="
                      stepsType !== 'map-maker' &&
                      stepsType !== 'brand-fingerprint'
                    "
                  ></span>
                  <span
                    v-html="langObj['s1-packtest-category'].quizStudy"
                    v-else
                  ></span>

                  <a-tooltip>
                    <template slot="title">
                      <span v-html="langObj['s1-packtest-category'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>

                <div class="position-r">
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-tree-select
                    v-model="categoryID"
                    class="fullWidth"
                    :replaceFields="{
                      children: 'Children',
                      title: 'CategoryName',
                      key: 'CategoryId',
                      value: 'CategoryId',
                    }"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :tree-data="categories"
                    :allowClear="true"
                    placeholder="Please select appropriate category"
                  >
                  </a-tree-select>
                </div>
              </div>

              <div class="input-group" v-if="showStudyType">
                <label>
                  <span v-html="langObj['s1-packtest-study-type'].quiz"></span>
                  <a-tooltip>
                    <template slot="title">
                      <span
                        v-html="langObj['s1-packtest-study-type'].tip"
                      ></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>

                <div class="position-r">
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-select
                    class="fullWidth"
                    placeholder="Please specify if Packaging restage or Shelf Optimization"
                    option-filter-prop="children"
                    show-search
                    :filter-option="filterCountry"
                    :key="selectDropdownsKey"
                    :default-value="
                      editableSurvey
                        ? editableSurvey.SurveyConfig.studyType
                        : undefined
                    "
                    @change="setStudyType($event)"
                  >
                    <a-select-option
                      v-for="study in [
                        { name: 'Pack', label: 'Packaging restage' },
                        { name: 'SBD', label: 'Shelf Optimization' },
                      ]"
                      :key="study.name"
                      :value="study.name"
                    >
                      {{ study.label }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
          </div>
          <StepNaviBar
            :stepIndex="stepIndex"
            :nextDisable="nextDisable"
            :cancelButton="editableSurvey ? false : true"
            :conciergeAssistance="surveyTypeID === 108 ? false : true"
            :stepsType="getStepsType()"
            @nextHandler="nextStep"
            @cancel:draft="cancelDraft"
            nextBtnTooltip="Go to Stimuli"
            @editable-survey-updated="handleEditableSurveyUpdate"
          />
        </div>
      </section>
    </div>
    <CompletionProgress :checkList="quizs"></CompletionProgress>
  </div>
</template>

<script>
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import Skeleton from "@/components/general/Skeleton.vue";
import CompletionProgress from "@/components/general/CompletionProgress.vue";
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import _ from "lodash";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import IconBase from "@/components/general/IconBase.vue";
import IconAdjustments from "@/components/icons/IconAdjustments.vue";

export default {
  name: "PackTestSettings",
  components: {
    ProgressBar,
    StepNaviBar,
    Skeleton,
    CompletionProgress,
    IconBase,
    IconAdjustments,
  },
  mixins: [surveyPriceMixin],
  props: {
    SurveyTypeId: Number,
  },
  data() {
    return {
      markets: [],
      langs: [],
      categories: [],
      curDate: "",
      categoryID: undefined,
      surveyName: "",
      brandName: "",
      countryID: undefined,
      langIndex: undefined,
      projectID: undefined,
      openCountry: false,
      langObj: {},
      client: {},
      stepIndex: 1,
      quizs: [],
      surveyID: 0,
      selectDropdownsKey: 0,
      editableSurvey: undefined,
      showSkeleton: false,
      brands: [],
      tempBrand: "",
      addBrandBtn: false,
      surveyTypeID: null,
      user: null,
      studyType: null,
      showStudyType: false,
      breadcrumbTitle: "Pack Test",
      stepsType: "",
      timeframe: "",
    };
  },
  computed: {
    nextDisable() {
      const commonChecks =
        this.surveyName === "" ||
        (this.brandName === "" &&
          this.surveyTypeID !== 108 &&
          this.surveyTypeID !== 109) ||
        this.categoryID === undefined ||
        this.langIndex === undefined ||
        this.countryID === undefined;

      if (this.surveyTypeID === 108 || this.surveyTypeID === 109) {
        return commonChecks || this.timeframe === "";
      }

      if (this.surveyTypeID === 107) {
        return commonChecks || this.studyType === null;
      }

      return commonChecks;
    },

    showBrandName() {
      return this.surveyTypeID !== 108 && this.surveyTypeID !== 109;
    },
    showTimeframe() {
      return this.surveyTypeID === 108 || this.surveyTypeID === 109;
    },
  },
  watch: {
    surveyName() {
      this.updateQuizStatus();
    },
    categoryID() {
      this.updateQuizStatus();
      this.getAudienceName();
    },
    langIndex() {
      this.updateQuizStatus();
    },
    countryID() {
      this.updateQuizStatus();
    },
    brandName() {
      this.updateQuizStatus();
    },
    studyType() {
      this.updateQuizStatus();
    },
    timeframe() {
      this.updateQuizStatus();
    },
  },
  methods: {
    selectCountry(value) {
      this.countryID = parseInt(value, 10);
      this.langs = this.markets.find((market) => {
        return parseInt(market.MarketId, 10) === parseInt(value, 10);
      }).mLanguages;

      if (this.editableSurvey) {
        this.langIndex = _.findIndex(this.langs, (o) => {
          return (
            o.LucidLanguageId == this.editableSurvey.SurveyConfig.LucidLangId
          );
        });
      } else if (this.langs != undefined && this.langs.length > 0) {
        this.selectLang(0);
      }
    },
    filterCountry(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    openDropdown() {
      this.openCountry = true;
    },
    selectLang(value) {
      this.langIndex = value;
    },
    nextStep() {
      this.getAudienceName();
      if (this.surveyTypeID === 108 || this.surveyTypeID === 109) {
        this.updateMapMakerSurvey();
      } else {
        this.updateSurvey();
      }
    },
    getAudienceName() {
      const findItemNested = (arr, itemId, nestingKey) =>
        arr.reduce((a, item) => {
          if (a) return a;
          if (item.CategoryId === itemId) return item;
          if (item[nestingKey])
            return findItemNested(item[nestingKey], itemId, nestingKey);
        }, null);

      const res = findItemNested(
        this.categories,
        Number(this.categoryID),
        "Children"
      );
      sessionStorage.setItem("audienceName", res ? res.AudienceName : null);
    },
    getCategoryInfoObject(object, string) {
      let result;
      if (!object || typeof object !== "object") return;
      Object.values(object).some((v) => {
        if (v === string) return (result = object);
        return (result = this.getCategoryInfoObject(v, string));
      });
      return result;
    },
    updateSurvey() {
      let surveyObj = {
        SurveyId: this.surveyID,
        SurveyName: this.surveyName,
        ProjectID: this.projectID,
        CountryId: this.countryID,
        CategoryId: this.categoryID,
        AlchemerLangId: this.langs[this.langIndex].AlchemerLanguageId,
        LucidLangId: this.langs[this.langIndex].LucidLanguageId,
        Brand: _.startCase(this.brandName),
        CommunicationObjective1: 0,
        CommunicationObjective2: 0,
        userId: this.user.userID,
        studyType: this.studyType,
      };

      const getCountryObject = this.markets.find(
        (element) => element.MarketId === this.countryID
      );

      let surveySummary = {
        country: getCountryObject.MarketName,
        projectName: this.surveyName,
        language: this.langs[this.langIndex].AlchemerLanguage,
        languageCode: this.langs[this.langIndex].AlchemerLanguageCode,
        category: this.getCategoryInfoObject(this.categories, this.categoryID)
          .CategoryName,
        categoryId: this.categoryID,
        brand: this.brandName,
        ReportOption:
          this.editableSurvey?.SurveyConfig?.ReportOption || "Automated",
        implicitComponent:
          this.editableSurvey?.SurveyConfig?.implicitComponent !== undefined
            ? this.editableSurvey?.SurveyConfig?.implicitComponent
            : true,
        studyType: this.studyType,
      };

      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({ ...currentSummaryData, ...surveySummary });
      const stepSavePromise = new Promise((resolve) => {
        wsUtils.UpdateSurveyPacktest(surveyObj, resolve);
      });

      stepSavePromise.then(() => {
        jsUtils.setSurveysApiTrigger(true);
        const nextRoute = this.surveyTypeID === 107 ? "stimuli-sbd" : "stimuli";
        this.$router.push({
          name: this.$route.meta.settingsNextRoute || nextRoute,
          params: {
            id: this.surveyID,
          },
        });
      });
    },
    updateQuizStatus() {
      this.quizs = [
        {
          quizName: this.langObj["s1-studyname"].robot,
          completed: this.surveyName !== "",
        },
        {
          quizName: this.langObj["s1-brand"].robot,
          completed: this.brandName !== "",
        },
        {
          quizName: this.langObj["s1-country"].robot,
          completed: this.countryID !== undefined,
        },
        {
          quizName: this.langObj["s1-language"].robot,
          completed: this.langIndex !== undefined,
        },
        {
          quizName: this.langObj["s1-category"].robot,
          completed: this.categoryID !== undefined,
        },
      ];
      if (this.surveyTypeID === 108 || this.surveyTypeID === 109) {
        this.quizs[1] = {
          quizName: this.langObj["s1-timeframe"].robot,
          completed: this.timeframe !== "",
        };
        // this.quizs.splice(1, 1);
      }
      if (this.surveyTypeID === 107) {
        this.quizs.push({
          quizName: this.langObj["s1-packtest-study-type"].robot,
          completed: this.studyType !== null,
        });
      }
    },
    setCurrentEditableSurveyData() {
      this.categoryID = this.editableSurvey.SurveyConfig.CategoryId;
      this.surveyName = this.editableSurvey.SurveyConfig.SurveyName;
      this.brandName = this.editableSurvey.SurveyConfig.BrandName;
      this.timeframe = this.editableSurvey.SurveyConfig.BrandName;
      this.studyType = this.editableSurvey.SurveyConfig.studyType || null;
      this.selectCountry(this.editableSurvey.SurveyConfig.CountryId);
      this.selectDropdownsKey = 1;
    },
    resetEditableSurveyData() {
      this.surveyName = "";
      this.brandName = "";
      this.langIndex = undefined;
      this.selectDropdownsKey = Math.random();
      this.countryID = undefined;
      this.categoryID = undefined;
      this.langs = [];
      this.studyType = null;
      this.timeframe = "";
    },
    showSkeletonLoader() {
      this.showSkeleton = true;
      setTimeout(() => {
        this.showSkeleton = false;
      }, 1000);
    },
    cancelDraft() {
      this.$router.push("/");
    },
    deleteSurvey() {
      const surveyServicesPromise = new Promise((resolve) => {
        let data = {
          SurveyId: this.surveyID,
          userId: this.user.EncrypteduserID,
        };

        const surveyData = {
          endpoint: "DeleteSurvey",
          data,
        };
        wsUtils.surveyServices(surveyData, resolve);
      });
      surveyServicesPromise.then(() => {});
    },
    brandSearch(value) {
      this.tempBrand = value;

      if (value !== "" && !this.brands.includes(value)) {
        this.addBrandBtn = true;
      } else {
        this.addBrandBtn = false;
      }

      if (value !== "") {
        const brandSearch = new Promise((resolve) => {
          wsUtils.BrandSearch(value, resolve);
        });
        brandSearch.then((data) => {
          _.forEach(data, (item) => {
            if (!this.brands.includes(item.Brand)) {
              this.brands.push(item.Brand);
            }
          });
        });
      }
    },
    addBrand() {
      if (this.tempBrand !== "" && !this.brands.includes(this.tempBrand)) {
        this.brands.push(this.tempBrand);
        this.brandName = this.tempBrand;
        this.selectDropdownsKey = Math.random();
        this.addBrandBtn = false;
      }
    },
    selectBrand(value) {
      this.brandName = value;
      this.selectDropdownsKey = Math.random();
      this.addBrandBtn = false;
    },
    getCustomQuestions() {
      const getCustomQuestionsPromise = new Promise((resolve) => {
        wsUtils.GetCustomQuestion(this.surveyID, resolve);
      });
      getCustomQuestionsPromise.then((data) => {
        let questionsType = data.CustomQuestions.map((item) => {
          return { QuestionType: item.questionType };
        });

        let surveyPriceSetData = {
          Question: questionsType,
        };
        let currentPackTestPriceData = jsUtils.getPackTestPrice();

        jsUtils.setPackTestPrice({
          ...currentPackTestPriceData,
          ...surveyPriceSetData,
        });
        if (this.surveyTypeID === 108) {
          this.getMapMakerTotalPrice();
        } else {
          this.getPackTestTotalPrice();
        }
      });
    },

    updatePackTestUI() {
      // 107 - Pack SBD test
      // 108 - Map Maker test
      // 109 - Brand Fingerprint test
      const surveyMapping = {
        107: {
          showStudyType: true,
          breadcrumbTitle: "Pack SBD Test",
          stepsType: "pack-sbd",
        },
        108: {
          showStudyType: false,
          breadcrumbTitle: "Map Maker Test",
          stepsType: "map-maker",
        },
        109: {
          showStudyType: false,
          breadcrumbTitle: "Brand Fingerprint",
          stepsType: "brand-fingerprint",
        },
      };

      const defaultSurvey = {
        showStudyType: false,
        breadcrumbTitle: "Pack Test",
        stepsType: "pack",
      };

      const survey = surveyMapping[this.surveyTypeID] || defaultSurvey;

      this.showStudyType = survey.showStudyType;
      this.breadcrumbTitle = survey.breadcrumbTitle;
      this.stepsType = survey.stepsType;
    },
    setStudyType(value) {
      this.studyType = value;
      this.quizs[5].completed = value !== null;
    },
    handleEditableSurveyUpdate(surveyData) {
      if (
        surveyData &&
        surveyData.SurveyConfig &&
        surveyData.SurveyConfig.BrandName &&
        surveyData.SurveyConfig.SurveyName
      ) {
        this.editableSurvey = surveyData;
        this.getCountryList();
        this.selectDropdownsKey = Math.random();
      } else {
        this.editableSurvey = undefined;
      }
    },
    getCountryList() {
      let countryListPromise = new Promise((resolve) => {
        wsUtils.MarketList(resolve);
      });
      countryListPromise.then((data) => {
        this.markets = data;
        if (this.editableSurvey) {
          this.setCurrentEditableSurveyData();
        } else {
          this.resetEditableSurveyData();
        }
      });
    },
    updateMapMakerSurvey() {
      let surveyObj = {
        SurveyId: this.surveyID,
        SurveyName: this.surveyName,
        ProjectID: this.projectID,
        CountryId: this.countryID,
        CategoryId: this.categoryID,
        AlchemerLangId: this.langs[this.langIndex].AlchemerLanguageId,
        LucidLangId: this.langs[this.langIndex].LucidLanguageId,
        Brand: this.timeframe,
        userId: this.user.userID,
      };

      const getCountryObject = this.markets.find(
        (element) => element.MarketId === this.countryID
      );

      let surveySummary = {
        country: getCountryObject.MarketName,
        projectName: this.surveyName,
        language: this.langs[this.langIndex].AlchemerLanguage,
        category: this.getCategoryInfoObject(this.categories, this.categoryID)
          .CategoryName,
        timeframe: this.timeframe,
        audience: this.audience,
        studyType: "",
      };

      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({ ...currentSummaryData, ...surveySummary });
      const stepSavePromise = new Promise((resolve) => {
        wsUtils.UpdateSurveyData1(surveyObj, resolve);
      });

      stepSavePromise.then(() => {
        jsUtils.setSurveysApiTrigger(true);
        this.$router.push({
          name:
            this.surveyTypeID === 108
              ? "map-maker-category-inputs"
              : "brand-fingerprint-category-inputs",
          params: { id: this.surveyID },
        });
      });
    },
    getNamePlaceholder() {
      const curDate = new Date().toLocaleDateString();
      switch (this.surveyTypeID) {
        case 108:
          return `eg: Map Maker Test ${curDate}`;
        case 109:
          return `eg: Brand Fingerprint ${curDate}`;
        default:
          return `eg: Pack Test ${curDate}`;
      }
    },
    getStepsType() {
      switch (this.surveyTypeID) {
        case 108:
          return "map-maker-test";
        case 109:
          return "brand-fingerprint";
        default:
          return "pack";
      }
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );

    this.user = jsUtils.getUserInfo();
    const categoryPromise = new Promise((resolve) => {
      wsUtils.GetCategories(
        {
          userid: this.user.EncrypteduserID,
        },
        resolve
      );
    });

    categoryPromise.then((data) => {
      this.categories = data;
    });

    this.curDate = new Date().toISOString().slice(0, 10);
  },
  beforeRouteLeave(to, from, next) {
    let editMode = this.editableSurvey !== undefined;
    const allowedRoutes = [
      "stimuli",
      "login",
      "samlssos-singout",
      "stimuli-sbd",
      "map-maker-category-inputs",
    ];
    if (!allowedRoutes.includes(to.name) && !editMode) {
      let modalText = this.nextDisable
        ? `If you leave the page this draft will be discarded and you will lost progress.`
        : `If you leave the page this draft will be discarded and you will lost progress.. Please click on the 'Next' button to save it.`;
      let _this = this;
      this.$confirm({
        title: "Discard draft",
        content: () => <div>{modalText}</div>,
        okText: "Continue",
        cancelText: "Discard study",
        onCancel() {
          _this.deleteSurvey();
          next();
        },
      });
    } else {
      next();
    }
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
    this.debounceBrandSearch = _.debounce((value) => {
      this.brandSearch(value);
    }, 400);
  },
  activated() {
    this.showSkeletonLoader();
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.updatePackTestUI();
    this.projectID = jsUtils.getWorkingProject();
    this.surveyID = this.$route.params.id;
    let surveyData = jsUtils.getEditableSurvey();

    if (
      surveyData &&
      surveyData.SurveyConfig &&
      surveyData.SurveyConfig.BrandName &&
      surveyData.SurveyConfig.SurveyName
    ) {
      this.editableSurvey = surveyData;
    } else {
      this.editableSurvey = undefined;
    }

    this.getCountryList();
    this.getCustomQuestions();
    this.updateQuizStatus();
  },
};
</script>

<style lang="scss" scoped></style>
