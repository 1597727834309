<template>
  <div class="selected-product">
    <div class="selected-product__info">
      <p>{{ product.ProductName }}</p>
      <div class="flex">
        <span> Price: {{ product.Price || "/" }}</span>
      </div>
    </div>
    <div class="selected-product__remove" @click="$emit('remove-product')">
      Remove
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectedProductItem",
  components: {},
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.selected-product {
  padding: 10px 62px 10px 15px;
  display: flex;
  border-right: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: -1px;
  width: 33.33%;
  position: relative;
  @media screen and (max-width: 980px) {
    width: 50%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  &:nth-child(3n + 3) {
    border-right: 0;
  }
  &__image {
    width: 50px;
    min-width: 50px;
    height: 35px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background: white;
    border: 1px solid #ececec;
    border-radius: 3px;
    margin-right: 10px;
    img {
      max-width: 90%;
      max-height: 90%;
      object-fit: contain;
    }
  }
  &__info {
    padding: 0 10px 0 0;
    overflow: hidden;
    p {
      margin: 0;
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 17px;
      padding-right: 50px;
    }
    span {
      font-size: 12px;
      padding-right: 10px;
    }
  }
  &__info-cta {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
  &__remove {
    position: absolute;
    right: 20px;
    top: 50%;
    color: #6c6c6c;
    font-size: 11px;
    transform: translateY(-50%);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #404040;
    }
  }
}
</style>
