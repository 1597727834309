<template>
  <div>
    <div class="box-wrapper mb-25">
      <p class="font-500">
        Please enter your age breaks. You have to enter a minimum of 2 and can
        add up to 6.
        <a-tooltip>
          <template slot="title">
            Please enter the age breaks you need in an x-y format, such as
            (18-24)</template
          >
          <a-icon type="info-circle" class="ml-5" />
        </a-tooltip>
      </p>
      <div class="quotas-fields">
        <div class="ant-input-group-addon-small input-group mb-15">
          <div
            v-for="(option, index) in ageInputs"
            :key="index"
            class="position-r"
          >
            <Skeleton :borderRadius="16" v-if="loadingSurveyData"></Skeleton>
            <a-input
              placeholder="18-34 years old"
              class="options-input js-age-input"
              v-model="option.name"
              @pressEnter="addInputs('ageInputs', index)"
            >
              <a-button
                slot="addonAfter"
                v-if="
                  option === '' ||
                  (!ageInputs[index + 1] && index + 1 < maxAgeInputs)
                "
                @click="addInputs('ageInputs', index)"
              >
                Add
              </a-button>
              <a-button
                v-else
                slot="addonAfter"
                class="ant-btn--delete"
                @click="removeInputs('ageInputs', index)"
              >
                Delete
              </a-button>
            </a-input>
          </div>
        </div>

        <a-button
          class="category-inputs__bulk-add"
          type="primary"
          @click="openBulkAddModal('ageInputs')"
        >
          Bulk Add
        </a-button>

        <p
          v-if="validationMessage.ageInputs"
          class="validation font-size-13 mb-0 mt-10"
        >
          {{ validationMessage.ageInputs }}
        </p>
      </div>
    </div>
    <div class="box-wrapper mb-25">
      <p class="font-500">
        Please enter your income breaks. You have to enter a minimum of 3 and
        can add up to 8. Please enter the income in the appropriate language and
        currency that you are fielding.
        <a-tooltip>
          <template slot="title">
            Please enter the income breaks you need in a format that the
            respondent will see. This means translated if needed.</template
          >
          <a-icon type="info-circle" class="ml-5" />
        </a-tooltip>
      </p>

      <div class="quotas-fields">
        <div class="ant-input-group-addon-small input-group mb-15">
          <div
            v-for="(option, index) in incomeInputs"
            :key="index"
            class="position-r"
          >
            <Skeleton :borderRadius="16" v-if="loadingSurveyData"></Skeleton>
            <a-input
              placeholder="$25,000 or less"
              class="options-input js-income-input"
              v-model="option.name"
              @pressEnter="addInputs('incomeInputs', index)"
            >
              <a-button
                slot="addonAfter"
                v-if="
                  option === '' ||
                  (!incomeInputs[index + 1] && index + 1 < maxIncomeInputs)
                "
                @click="addInputs('incomeInputs', index)"
              >
                Add
              </a-button>
              <a-button
                v-else
                slot="addonAfter"
                class="ant-btn--delete"
                @click="removeInputs('incomeInputs', index)"
              >
                Delete
              </a-button>
            </a-input>
          </div>
        </div>

        <a-button
          class="category-inputs__bulk-add"
          type="primary"
          @click="openBulkAddModal('incomeInputs')"
        >
          Bulk Add
        </a-button>
        <p
          v-if="validationMessage.incomeInputs"
          class="validation font-size-13 mb-0 mt-10"
        >
          {{ validationMessage.incomeInputs }}
        </p>
      </div>
    </div>
    <div class="box-wrapper mb-25">
      <p class="font-500">
        Please enter your regions. You have to enter a minimum of 3 and can add
        up to 15. Please enter the regions in the appropriate language that you
        are fielding if the respondent will be answering them directly.
        <a-tooltip>
          <template slot="title">
            Please enter the regions you need in a format that the respondent
            will see, unless special logic is needed to assign to breaks (for
            eg. California, Las Vegas are West). This means translated if
            needed. USA region logic already exists in template. Olena will have
            to add new logic if a new market requires this.</template
          >
          <a-icon type="info-circle" class="ml-5" />
        </a-tooltip>
      </p>

      <div class="quotas-fields">
        <div class="ant-input-group-addon-small input-group mb-15">
          <div
            v-for="(option, index) in regionsInputs"
            :key="index"
            class="position-r"
          >
            <Skeleton :borderRadius="16" v-if="loadingSurveyData"></Skeleton>
            <a-input
              placeholder="Scotland"
              class="options-input js-regions-input"
              v-model="option.name"
              @pressEnter="addInputs('regionsInputs', index)"
            >
              <a-button
                slot="addonAfter"
                v-if="
                  option === '' ||
                  (!regionsInputs[index + 1] && index + 1 < maxRegionsInputs)
                "
                @click="addInputs('regionsInputs', index)"
              >
                Add
              </a-button>
              <a-button
                v-else
                slot="addonAfter"
                class="ant-btn--delete"
                @click="removeInputs('regionsInputs', index)"
              >
                Delete
              </a-button>
            </a-input>
          </div>
        </div>

        <a-button
          class="category-inputs__bulk-add"
          type="primary"
          @click="openBulkAddModal('regionsInputs')"
        >
          Bulk Add
        </a-button>
        <p
          v-if="validationMessage.regionsInputs"
          class="validation font-size-13 mb-0 mt-10"
        >
          {{ validationMessage.regionsInputs }}
        </p>
      </div>
    </div>

    <a-modal
      title="Bulk Add "
      :visible="bulkAddModal"
      @ok="bulkAdd(activeListName)"
      @cancel="bulkAddModal = false"
      cancelText="Cancel"
      okText="Add"
    >
      <div class="input-group position-r">
        <label>{{ introText }}</label>
        <a-textarea v-model="bulkAddContent" :auto-size="{ minRows: 8 }" />
        <div v-if="errorMessage" class="validation">{{ errorMessage }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import Skeleton from "@/components/general/Skeleton.vue";
export default {
  name: "CustomQuotasFields",
  components: { Skeleton },
  props: {
    country: String,
    activeStep: Number,
  },
  data() {
    return {
      bulkAddModal: false,
      maxAgeInputs: 6,
      maxIncomeInputs: 8,
      maxRegionsInputs: 15,
      ageInputs: [{ id: -1, name: "" }],
      incomeInputs: [{ id: -1, name: "" }],
      regionsInputs: [{ id: -1, name: "" }],
      loadingSurveyData: false,
      bulkAddContent: "",
      activeListName: "",
      errorMessage: "",
      validationMessage: {
        ageInputs: "",
        incomeInputs: "",
        regionsInputs: "",
      },
      quizs: [],
    };
  },
  computed: {
    introText() {
      const introTexts = {
        ageInputs:
          "Please copy and paste your full list of age breaks below. Enter each age range on a new line.",
        incomeInputs:
          "Please copy and paste your full list of income breaks below. Enter each income range on a new line.",
        regionsInputs:
          "Please copy and paste your full list of regions below. Enter each region on a new line.",
      };
      return introTexts[this.activeListName];
    },
    shouldUseDefault() {
      return (
        this.country.toLowerCase() === "united states" &&
        this.ageInputs.filter((item) => item.name.trim() !== "").length === 0 &&
        this.incomeInputs.filter((item) => item.name.trim() !== "").length ===
          0 &&
        this.regionsInputs.filter((item) => item.name.trim() !== "").length ===
          0 &&
        this.activeStep === 1
      );
    },
  },
  methods: {
    getCustomQuotaConfig() {
      this.loadingSurveyData = true;
      const model = {
        wsName: "GetSurveyQuotaConfig",
        params: {
          surveyId: this.$route.params.id,
        },
      };

      const getQuota = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      getQuota.then((data) => {
        if (Array.isArray(data)) {
          // Reset inputs
          this.ageInputs = [{ id: -1, name: "" }];
          this.incomeInputs = [{ id: -1, name: "" }];
          this.regionsInputs = [{ id: -1, name: "" }];
          this.quizs = [];

          // Populate inputs based on category
          data.forEach((item) => {
            let targetInputs;

            switch (item.quotaCategory) {
              case "Age":
                targetInputs = "ageInputs";
                break;
              case "Income":
                targetInputs = "incomeInputs";
                break;
              case "Region":
                targetInputs = "regionsInputs";
                break;
            }

            if (targetInputs) {
              this[targetInputs] = item.quotaList.length
                ? item.quotaList
                : [{ id: -1, name: "" }];

              // Add to quizs
              this.quizs.push({
                id: item.quotaCategory,
                quizName: `Add ${item.quotaCategory} breaks`,
                completed: false,
              });
            }

            this.$emit("update:quizs", this.quizs);
          });
        } else {
          console.error("Invalid data format", data);
        }
        this.loadingSurveyData = false;
      });
    },
    validateAllInputs() {
      if (
        this.country.toLowerCase() === "united states" &&
        this.ageInputs.filter((item) => item.name.trim() !== "").length === 0 &&
        this.incomeInputs.filter((item) => item.name.trim() !== "").length ===
          0 &&
        this.regionsInputs.filter((item) => item.name.trim() !== "").length ===
          0
      ) {
        this.validationMessage = {
          ageInputs: "",
          incomeInputs: "",
          regionsInputs: "",
        };
        this.$emit("nextStep");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }

      const validationConfig = {
        ageInputs: { min: 2, label: "age breaks" },
        incomeInputs: { min: 3, label: "income breaks" },
        regionsInputs: { min: 3, label: "region breaks" },
      };

      let allValid = true;

      // Iterate through each input list and validate
      for (const listName in validationConfig) {
        const config = validationConfig[listName];
        const currentInputs = this[listName].filter(
          (item) => item.name.trim() !== "" // Check if the name field is not empty
        ).length;

        // Check if the minimum required inputs are met
        if (currentInputs < config.min) {
          allValid = false;
          this.$set(
            this.validationMessage,
            listName,
            `Woops! You need to add at least ${config.min} ${config.label} to continue.`
          );
        } else {
          this.$set(this.validationMessage, listName, "");
        }
      }

      // If all inputs are valid, proceed to save
      if (allValid) {
        this.saveCustomQuotas();
      } else {
        // Show an error notification if validation fails
        this.$notification["error"]({
          message: "Validation Error",
          description:
            "Please make sure all required fields are filled correctly.",
          placement: "bottomLeft",
          duration: 6,
        });
      }
    },

    saveCustomQuotas() {
      const quotaDetails = [
        {
          quotaCategory: "Age",
          quotaList: this.ageInputs.filter((item) => item.name.trim() !== ""),
        },
        {
          quotaCategory: "Income",
          quotaList: this.incomeInputs.filter(
            (item) => item.name.trim() !== ""
          ),
        },
        {
          quotaCategory: "Region",
          quotaList: this.regionsInputs.filter(
            (item) => item.name.trim() !== ""
          ),
        },
      ];

      const data = {
        wsName: "SaveQuotaConfig",
        data: {
          surveyId: this.$route.params.id,
          quotaDetails,
        },
      };

      const saveQuotas = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      saveQuotas.then((data) => {
        if (data.Success) {
          this.$emit("nextStep", "custom");
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Save quotas failed. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
    openBulkAddModal(listName) {
      this.activeListName = listName;
      this.bulkAddModal = true;
      this.errorMessage = "";
      this.bulkAddContent = "";
    },
    addInputs(listName, index) {
      if (this[listName][index].name !== "") {
        const maxInputs = {
          ageInputs: this.maxAgeInputs,
          incomeInputs: this.maxIncomeInputs,
          regionsInputs: this.maxRegionsInputs,
        }[listName];

        if (this[listName].length < maxInputs) {
          this[listName].push({ id: -1, name: "" });
          setTimeout(() => {
            const optionInputs = document.querySelectorAll(
              `.js-${listName.replace("Inputs", "").toLowerCase()}-input input`
            );
            optionInputs[this[listName].length - 1].focus();
          }, 200);
        }
      }
    },
    removeInputs(listName, index) {
      // Remove the item at the specified index
      this[listName].splice(index, 1);

      // Ensure the list always has at least one empty object
      if (this[listName].length === 0) {
        this[listName].push({ id: -1, name: "" });
      }
    },
    bulkAdd(listName) {
      this.errorMessage = "";
      const items = this.bulkAddContent
        .split("\n")
        .map((item) => item.trim())
        .filter((item) => item !== "");
      const maxInputs = {
        ageInputs: this.maxAgeInputs,
        incomeInputs: this.maxIncomeInputs,
        regionsInputs: this.maxRegionsInputs,
      }[listName];
      const currentInputs = this[listName].filter(
        (item) => item.name !== ""
      ).length;

      if (items.length + currentInputs > maxInputs) {
        const extraItems = items.length + currentInputs - maxInputs;
        const listLabel = {
          ageInputs: "age breaks",
          incomeInputs: "income breaks",
          regionsInputs: "regions",
        }[listName];
        this.errorMessage = `Woops! You've added too many ${listLabel}. Please remove ${extraItems} to continue.`;
      } else {
        this[listName] = this[listName].filter((item) => item.name !== "");
        this[listName].push(
          ...items.map((item) => ({
            id: -1,
            name: item,
          }))
        );
        this.bulkAddModal = false;
        this.bulkAddContent = "";
      }
    },
    updateQuizStatus(category, inputs, minRequired, label) {
      const currentInputs = inputs.filter(
        (item) => item.name.trim() !== ""
      ).length;

      // Find existing quiz item or create a new one
      const existingQuiz = this.quizs.find((quiz) => quiz.id === category);

      if (existingQuiz) {
        // Update existing quiz item
        existingQuiz.completed = currentInputs >= minRequired;
      } else {
        // Add new quiz item
        this.quizs.push({
          id: category,
          quizName: `Add ${label}`,
          completed: currentInputs >= minRequired,
        });
      }

      // Emit updated quizs
      this.$emit("update:quizs", this.quizs);
    },
  },
  watch: {
    shouldUseDefault(newValue) {
      if (newValue) {
        this.$emit("nextBtnLabel", "Use default");
      } else {
        this.$emit("nextBtnLabel", "Next");
      }
    },
    ageInputs: {
      handler() {
        this.updateQuizStatus("Age", this.ageInputs, 2, "age breaks");
      },
      deep: true,
    },
    incomeInputs: {
      handler() {
        this.updateQuizStatus("Income", this.incomeInputs, 3, "income breaks");
      },
      deep: true,
    },
    regionsInputs: {
      handler() {
        this.updateQuizStatus("Region", this.regionsInputs, 3, "region breaks");
      },
      deep: true,
    },
    activeStep: {
      handler() {
        this.$emit("update:quizs", this.quizs);
      },
    },
  },
  activated() {
    this.getCustomQuotaConfig();
    console.log("activated custom quotas fields");
  },
};
</script>

<style lang="scss" scoped>
.quotas-fields {
  .ant-input-group-addon-small {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }
}

::v-deep .input-group .options-input .ant-input-group-addon {
  min-width: 52px;
  width: 52px;
  background: transparent;
  .ant-btn {
    border: 0;
    height: 48px;
  }
}

::v-deep .input-group .options-input .ant-input {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
