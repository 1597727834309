<template>
  <div class="rank-example">
    <label>{{ exampleQuestionText }}</label>
    <div class="flex w-full justify-between">
      <div class="rank-example__column">
        <draggable
          class="list-group"
          tag="ul"
          :list="list"
          v-bind="dragOptions"
          group="rankExample"
        >
          <li
            class="list-group-item"
            v-for="element in list"
            :key="element.name.optionText"
          >
            <font-awesome-icon icon="arrow-right" />
            {{ element.name.optionText }}
          </li>
        </draggable>
      </div>
      <div class="rank-example__column">
        <draggable
          class="list-group"
          :class="{ 'list-group--empty': list2.length === 0 }"
          tag="ul"
          :list="list2"
          v-bind="dragOptions"
          group="rankExample"
        >
          <li
            class="list-group-item list-group-item--order"
            v-for="element in list2"
            :key="element.name.optionText"
          >
            {{ element.name.optionText }}
          </li>
        </draggable>
      </div>
    </div>

    <QuestionSettings
      v-if="questionSettings"
      @editItem="editItem"
      @deleteTempQuestion="$emit('deleteTempQuestion')"
      @setAsScreener="$emit('setAsScreener', $event)"
      @saveInLibrary="$emit('saveInLibrary', $event)"
      @addSavedQuestion="$emit('addSavedQuestion')"
      :langObj="langObj"
      :setAsScreener="setAsScreener"
      :showSetAsScreener="showSetAsScreener"
      :saveInLibrary="saveInLibrary"
      :showSaveInLibrary="showSaveInLibrary"
      :hideEditButton="hideEditButton"
      :hideAddButton="hideAddButton"
      :insertCustomQuestionsLoading="insertCustomQuestionsLoading"
    ></QuestionSettings>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import QuestionSettings from "@/components/questions/QuestionSettings.vue";

export default {
  name: "RankExample",
  props: {
    exampleQuestionText: {
      type: String,
    },
    options: {
      type: Array,
      default() {
        return [
          { optionText: "Lorem ipsum dolor sit" },
          { optionText: "Duis aute irure dolor" },
          { optionText: "Qui officia deserunt" },
          { optionText: "Excepteur sint occaecat" },
        ];
      },
    },
    options2: {
      type: Array,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
    },
    langObj: {
      type: Object,
    },
    questionType: {
      type: String,
    },
    questionSettings: {
      type: Boolean,
    },
    setAsScreener: {
      type: Boolean,
    },
    showSetAsScreener: {
      type: Boolean,
    },
    saveInLibrary: {
      type: Boolean,
    },
    showSaveInLibrary: {
      type: Boolean,
    },
    hideEditButton: {
      type: Boolean,
    },
    hideAddButton: {
      type: Boolean,
    },
    insertCustomQuestionsLoading: {
      type: Boolean,
    },
  },
  components: {
    draggable,
    QuestionSettings,
  },
  data() {
    return {
      list2: this.options2.map((name, index) => {
        return { name, id: index };
      }),
    };
  },
  methods: {
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    editItem() {
      let info = {
        index: this.index,
        questionType: this.questionType,
      };

      this.$emit("editQuestion", info);
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    list() {
      return this.options.map((name, index) => {
        return { name, id: index };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #f9f9f9;
}
.list-group {
  padding: 0;
  margin: 0;
  min-height: 43px;
  background: #fff;
  &--empty {
    border: 1px dashed #ebeaea;
    position: relative;
    color: #adadad;
    &:before {
      margin-right: 2px;
      content: "1.";
      text-align: center;
      display: inline-block;
      font-size: 14px;
      padding: 10px;
      position: absolute;
    }
  }
}
.list-group-item {
  cursor: move;
  padding: 10px;
  border: 1px solid #ebeaea;
  margin-top: -1px;
  transition: background 0.3s ease;
  position: relative;
  font-size: 14px;
  &:hover {
    background: #f9f9f9;
    svg {
      opacity: 1;
    }
  }
  svg {
    width: 10px;
    height: 20px;
    position: absolute;
    right: 10px;
    opacity: 0.6;
    transition: all 0.3s ease;
  }
  &--order {
    counter-increment: item;
    &:before {
      margin-right: 2px;
      content: counter(item) ".";
      text-align: center;
      display: inline-block;
    }
  }
}
.rank-example {
  &__column {
    width: calc(50% - 5px);
    padding: 10px;
    border: 1px solid #efefef;
  }
}
</style>
