<template>
  <div class="product-list">
    <div class="page-title">
      <icon-base
        :width="32"
        :height="33"
        :viewBox1="32"
        :viewBox2="33"
        iconName="chart"
        iconColor="transparent"
        ><icon-chart-histogram
      /></icon-base>
      <div class="page-title__text">
        <h1>Stimuli</h1>
        <span>Select Test Products</span>
      </div>
    </div>

    <div class="box-wrapper">
      <StimuliTitle
        class="mb-5 mt-20"
        v-if="!brandProductsList.length"
        :title="
          !noBrandProducts
            ? 'Select a brand to see a list of products.'
            : 'There are no products for the selected brand.'
        "
      ></StimuliTitle>

      <div
        class="flex items-center justify-between pt-0 management-system__tools"
      >
        <Skeleton v-if="loadingFilters"></Skeleton>
        <div class="flex items-center management-system__filters">
          <div
            class="
              management-system__filters-item
              input-group input-group--small
            "
            v-if="showBrandDropdown && !loadingBrandList"
          >
            <a-select
              class="fullWidth"
              option-filter-prop="children"
              show-search
              placeholder="Select a brand"
              :key="Math.random()"
              @change="getProducts('click', $event)"
              :default-value="defaultBrand || undefined"
            >
              <a-select-option
                v-for="item in uniqueBrandList"
                :key="item"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </div>
          <div
            v-if="!showBrandDropdown && !loadingBrandList"
            class="product-list__selected-brand mr-10"
          >
            <strong>Selected brand:</strong> {{ surveyBrand }}
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                <span>Change selected brand</span>
              </template>
              <font-awesome-icon
                icon="edit"
                class="cursor-pointer"
                @click="showBrandDropdown = true"
              />
            </a-tooltip>
          </div>
          <div
            class="
              management-system__filters-item
              management-system__filters-item--page
              input-group input-group--small
            "
          >
            <template v-if="searchProductList.length > 10">
              <span>Page</span>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                placeholder="Please select"
                :key="Math.random()"
                @change="currentPage = $event"
                :default-value="currentPage || 1"
              >
                <a-select-option
                  v-for="page in totalPages"
                  :key="page"
                  :value="page"
                >
                  {{ page }}
                </a-select-option>
              </a-select>
              <span>of {{ totalPages }}</span>
            </template>
          </div>
        </div>
        <p class="management-system__tools-counter" v-if="productsList.length">
          {{ selectedRowKeys.length }} selected product<template
            v-if="selectedRowKeys.length !== 1"
            >s</template
          >
          <span
            class="management-system__tools-counter-preview"
            v-if="selectedProducts.length > 0"
            @click="productsQuickPreview = !productsQuickPreview"
            >{{ productsQuickPreviewTitle }}</span
          >
        </p>
        <div class="flex items-center management-system__filters justify-end">
          <div
            class="
              management-system__filters-item
              management-system__filters-item--search
              input-group input-group--small
            "
            :class="{
              'management-system__filters-item--search-first-field':
                !showBrandDropdown,
            }"
            v-if="
              brandProductsList.length &&
              viewType === 'list' &&
              !loadingBrandList
            "
          >
            <div class="position-r search-field">
              <icon-base
                :width="20"
                :height="20"
                :viewBox1="64"
                :viewBox2="64"
                iconName="searchThin"
                iconColor="transparent"
                iconStroke="#595959"
                class="search-icon"
                ><icon-search-thin
              /></icon-base>
              <a-input
                :class="{ active: searchParametars != '' }"
                v-model="searchParametars"
                placeholder="Search"
              >
              </a-input>
            </div>
          </div>
        </div>
      </div>
      <div
        class="product-list__selected flex flex-wrap"
        v-if="productsQuickPreview && selectedProducts.length > 0"
      >
        <SelectedProductItem
          v-for="product in selectedProducts"
          :key="product.UPC"
          :product="product"
          @remove-product="selectRow(product)"
        ></SelectedProductItem>
      </div>
      <StimuliTitle
        title="Select a Product to test. Click a product again to deselect."
        v-if="brandProductsList.length"
        class="mb-25"
      ></StimuliTitle>
      <div v-if="viewType === 'list'">
        <div class="management-system__grid" v-if="brandProductsList.length">
          <ProductItem
            v-for="item in paginatedProducts"
            :key="item.UPC"
            :product="item"
            @item-click="selectRow(item)"
            :loading="loading"
            :class="{ selected: isSelected(item.UPC) }"
          ></ProductItem>
        </div>
      </div>
    </div>
    <div class="flex justify-between stimuli-nav">
      <a-button @click="prevStep"
        ><icon-base
          class="mr-5 back"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#D04395"
          ><icon-long-arrow-right
        /></icon-base>
        Back</a-button
      >
      <a-button
        type="primary"
        :disabled="selectedRowKeys.length === 0"
        @click="nextStep"
        >Next
        <icon-base
          class="ml-5"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#fff"
          ><icon-long-arrow-right /></icon-base
      ></a-button>
    </div>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import SelectedProductItem from "@/components/packTestCustom/SelectedProductItem.vue";
import Skeleton from "@/components/general/Skeleton.vue";
import _ from "lodash";
import IconBase from "@/components/general/IconBase.vue";
import IconChartHistogram from "@/components/icons/IconChartHistogram.vue";
import IconLongArrowRight from "@/components/icons/IconLongArrowRight.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import ProductItem from "@/components/packTestSurvey/ProductItem.vue";

const columns = [
  {
    title: "Product",
    dataIndex: "ProductName",
  },
  {
    title: "Brand",
    dataIndex: "Brand",
  },
  {
    title: "Price",
    scopedSlots: { customRender: "Price" },
  },
];

export default {
  name: "ProductList",
  components: {
    StimuliTitle,
    SelectedProductItem,
    Skeleton,
    IconBase,
    IconChartHistogram,
    IconLongArrowRight,
    IconSearchThin,
    ProductItem,
  },
  props: {
    activeShelf: {
      type: Object,
    },
    selectedProductsKeys: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedProducts: {
      type: Array,
      default() {
        return [];
      },
    },
    productsList: {
      type: Array,
      default() {
        return [];
      },
    },
    shelfImageId: {
      type: Number,
    },
    currentBrand: {
      type: String,
    },
    uniqueBrandList: {
      type: Array,
      default() {
        return [];
      },
    },
    currentShelfId: {
      type: Number,
    },
    surveyBrand: {
      type: String,
    },
    currency: {
      type: String,
      default() {
        return "$";
      },
    },
  },
  data() {
    return {
      brandList: [],
      brandProductsList: [
        {
          productName: "Lorem 1",
          areaID: 1,
        },
        {
          productName: "Lorem 2",
          areaID: 2,
        },
        {
          productName: "Lorem 3",
          areaID: 3,
        },
        {
          productName: "Lorem 4",
          areaID: 4,
        },
        {
          productName: "Lorem 5",
          areaID: 5,
        },
        {
          productName: "Lorem 6",
          areaID: 6,
        },
      ],
      chosenBrand: null,
      columns,
      pagination: {},
      selectedRowKeys: [],
      viewType: "list",
      previewShelfKey: null,
      noBrandProducts: false,
      searchParametars: "",
      loading: false,
      productsQuickPreview: false,
      loadingFilters: true,
      showBrandDropdown: false,
      loadingBrandList: false,
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    paginatedProducts() {
      let start = (this.currentPage - 1) * this.pageSize;
      let end = start + this.pageSize;
      return this.searchProductList.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.searchProductList.length / this.pageSize);
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
      };
    },
    searchProductList() {
      return this.productsList.filter((item) => {
        const searchParams = this.searchParametars.toLowerCase().split(" ");
        const brandMatches =
          this.currentBrand !== null
            ? item.Brand.toLowerCase() === this.currentBrand.toLowerCase()
            : item.Brand.toLowerCase() === this.surveyBrand.toLowerCase();

        return (
          brandMatches &&
          searchParams.every((v) => item.ProductName.toLowerCase().includes(v))
        );
      });
    },
    defaultBrand() {
      let brand = this.chosenBrand || this.currentBrand;

      let uniqueBrandList = this.uniqueBrandList.map(function (item) {
        return item.toLowerCase();
      });

      let index = uniqueBrandList.indexOf(this.surveyBrand.toLowerCase());

      if (index !== -1) {
        brand = this.chosenBrand || this.currentBrand || this.surveyBrand;
      }
      return _.startCase(brand);
    },
    productsQuickPreviewTitle() {
      return this.productsQuickPreview ? "Hide quick preview" : "Quick preview";
    },
  },
  watch: {
    activeShelf() {
      this.selectedRowKeys = [];
      // this.getBrandList();
    },
    searchProductList() {
      this.$nextTick(() => {
        this.setPagination();
      });
    },
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    getProducts(event, brand) {
      let index = this.uniqueBrandList.findIndex(
        (item) => item.toUpperCase() === this.surveyBrand.toUpperCase()
      );
      if (
        (this.currentBrand !== undefined && this.currentBrand !== null) ||
        index !== -1
      ) {
        if (this.selectedRowKeys.length > 0) {
          let _this = this;
          this.$confirm({
            title: "Change brand",
            content: () => (
              <div>
                Are you sure you want to change a brand? If you change it, all
                products you already selected will be deleted.
              </div>
            ),
            onCancel() {
              if (brand !== undefined) {
                _this.setBrand(brand);
              }
              _this.changeBrand(event);
            },
            okText: "No",
            cancelText: "Yes, change it",
          });
        } else {
          if ((brand !== undefined && index !== -1) || event === "click") {
            this.setBrand(brand);
          }
          this.changeBrand(event);
        }
      } else if (event === "click" && brand === undefined) {
        this.$notification["warning"]({
          message: `Oops`,
          description:
            "Please select a brand from the dropdown list then click on the 'Search Products' button.",
          placement: "bottomLeft",
          duration: 5,
        });
      } else {
        if (event === "click" || (event !== "click" && index !== -1)) {
          this.setBrand(brand);
          this.changeBrand(event);
        }
      }
    },
    changeBrand(event) {
      this.loading = true;
      this.loading = false;
      let index = this.uniqueBrandList.findIndex(
        (brand) => _.startCase(brand) === _.startCase(this.surveyBrand)
      );
      if (index !== -1 && this.surveyBrand !== this.currentBrand) {
        this.setBrand(this.surveyBrand);
        event = "custom";
      } else {
        this.setBrand(
          this.chosenBrand || this.currentBrand || this.surveyBrand
        );
      }

      this.setBrand(this.chosenBrand || this.currentBrand || this.surveyBrand);
      this.selectedRowKeys = [];
      if (event !== "click" && event !== "custom") {
        this.selectedRowKeys = [...this.selectedProductsKeys];
      }

      this.setProductsDetails();

      this.$emit("brandChanged", { brand: this.chosenBrand, event: event });
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.selectRow(record);
          },
        },
      };
    },
    selectRow(record) {
      const selectedRowKeys = [...this.selectedRowKeys];
      if (selectedRowKeys.indexOf(record.UPC) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.UPC), 1);
        setTimeout(() => {
          this.setProductsDetails();
        }, 0);
      } else {
        if (this.selectedRowKeys.length < 10000) {
          selectedRowKeys.push(record.UPC);
          setTimeout(() => {
            this.setProductsDetails();
          }, 0);
        } else {
          this.$notification["warning"]({
            message: `Oops`,
            description: "The maximum number of selected products is 10000.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
      }
      this.selectedRowKeys = selectedRowKeys;
    },
    selectedEditableProducts(e) {
      if (
        this.selectedRowKeys.length < 10000 ||
        this.selectedRowKeys.length > e.length
      ) {
        this.selectedRowKeys = e;
        setTimeout(() => {
          this.setProductsDetails();
        }, 0);
      } else {
        this.$notification["warning"]({
          message: `Oops`,
          description: "The maximum number of selected products is 10000.",
          placement: "bottomLeft",
          duration: 4,
        });
      }
    },
    setProductsDetails() {
      let products = [];
      this.selectedRowKeys.forEach((item) => {
        let product = this.productsList.find((product) => product.UPC === item);
        if (product !== undefined) {
          products.push(product);
        }
      });
      this.$emit("products-details", products);
    },
    setBrand(brand) {
      this.chosenBrand = brand;
      this.$emit("brand", brand);
    },
    setBrandDropdown() {
      let index = this.uniqueBrandList.findIndex(
        (brand) => brand.toUpperCase() === this.surveyBrand.toUpperCase()
      );

      index === -1
        ? (this.showBrandDropdown = true)
        : (this.showBrandDropdown = false);
    },
    nextStep() {
      this.setProductsDetails();
      this.$emit("nextStep");
    },
    prevStep() {
      this.$emit("prevStep");
    },
    setPagination() {
      const pagination = { ...this.pagination };
      pagination.total = this.searchProductList.length;
      pagination.hideOnSinglePage = true;
      pagination.current = 1;
      this.pagination = pagination;
    },
    isSelected(UPC) {
      return this.selectedRowKeys.some((item) => item === UPC);
    },
  },
  mounted() {},
  created() {
    // let imageId = this.activeShelf
    //   ? this.activeShelf.imageID
    //   : this.shelfImageId;
    // if (imageId !== null) {
    //   this.getBrandList();
    // }
    this.getProducts();
    this.setBrandDropdown();
    this.setPagination();

    setTimeout(() => {
      this.loadingFilters = false;
    }, 800);
  },
  activated() {},
};
</script>

<style lang="scss" scoped>
.product-list {
  .box-wrapper {
    padding-top: 8px;
  }
  &__selected {
    background: #fafafa;
    margin-bottom: 20px;
    border: 1px solid #f4f4f4;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  &__image {
    width: 70px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 80px;
    }
  }
  &__table-cta {
    .ant-btn {
      height: 33px;
    }
  }
  &__selected-brand {
    font-size: 15px;
    padding-top: 10px;
    strong {
      font-weight: 500;
    }
    svg {
      color: #646464;
      margin-left: 5px;
      transition: color 0.3s ease;
      &:hover {
        color: #4318ff;
      }
    }
  }
  ::v-deep .custom-table {
    thead {
      border-color: #fff;
      .ant-table-selection {
        display: none;
      }
    }
    tbody {
      tr {
        border-color: #fff;
        td {
          &:nth-child(2) {
            min-width: 230px;
          }
          &.ant-table-selection-column {
            > span {
              pointer-events: none;
            }
          }
        }
        &.ant-table-row-selected td {
          background: #e7f4fb !important;
        }
      }
    }
    &.disable-select {
      tr:not(.ant-table-row-selected) {
        .ant-table-selection-column span {
          pointer-events: none;
        }
      }
    }
  }
  .management-system {
    &__tools {
      min-height: 84px;
      position: relative;
      margin-bottom: 3px;
      padding-bottom: 0;
      gap: 20px;
      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
      }
    }
    &__shelf-info {
      position: absolute;
      bottom: -81px;
      left: 0;
      font-size: 12px;
      margin-bottom: 0;
    }
    &__filters-item {
      > * {
        color: #4c4c4c;
        font-weight: 500;
      }
      ::v-deep .ant-select {
        .ant-select-selection {
          &--single {
            border-color: #e0e0e0;
          }
          &__placeholder {
            color: #b0b0b0;
          }
        }
      }
      ::v-deep .ant-input {
        border-color: #b0b0b0;
      }
      &--brand {
        display: none;
      }
      &--brand-show {
        display: block;
      }
      &--search {
        position: relative;
        ::v-deep .ant-input {
          border-color: #f4f4f4;
          background: #f4f4f4;
        }
      }
      &--search-first-field {
        // margin-left: 0;
        &:after {
          top: 27px;
        }
        // @media screen and (max-width: 800px) {
        //   width: 100%;
        //   max-width: 100%;
        // }
      }
    }
    &__view-cta {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    &__tools-counter {
      @media screen and (max-width: 800px) {
        padding-top: 13px;
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>
