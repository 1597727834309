<template>
  <div>
    <div class="page-title">
      <icon-base
        :width="32"
        :height="33"
        :viewBox1="32"
        :viewBox2="33"
        iconName="chart"
        iconColor="transparent"
        ><icon-chart-histogram
      /></icon-base>
      <div class="page-title__text">
        <h1>Stimuli</h1>
        <span>Upload shelf data</span>
      </div>
    </div>

    <div class="box-wrapper">
      <StimuliTitle
        title="Please provide shelf details that can be used to assemble the virtual shopping environment. Providing all details will reduce costs and timing for your study, but the shelf can be assembled by simply providing Planogram and pricing details."
      ></StimuliTitle>

      <div class="shelf-upload flex">
        <div class="shelf-upload__left">
          <div>
            <a-upload-dragger
              name="file"
              :multiple="true"
              :showUploadList="false"
              :customRequest="reportUpload"
              ref="upload"
            >
              <div>
                <img src="/images/filesIcons/upload.svg" alt="" />
                <strong>Drag and drop files</strong
                ><span>or browse files on your computer</span>
              </div>
            </a-upload-dragger>
          </div>
        </div>
        <div class="shelf-upload__right flex flex-1">
          <div class="mr-20 flex-1 shelf-upload__right-col">
            <h2>Required information</h2>
            <div
              class="shelf-upload__info-item shelf-upload__info-item--psa flex"
              :class="{
                'is-active':
                  (shelfData.psa !== null && shelfData.psa.success) ||
                  shelfUploadData !== null,
                error: shelfData.psa !== null && !shelfData.psa.success,
              }"
            >
              <Loader
                text="Uploading .psa file..."
                v-if="fileUploading.psa"
              ></Loader>
              <div class="shelf-upload__info-item-icon">
                <font-awesome-icon icon="check-circle" />
                <img src="/images/filesIcons/psa.svg" alt="" />
              </div>
              <div class="shelf-upload__info-item-content">
                <h3>1. Control shelf planogram (.psa)*</h3>
                <p v-if="shelfData.psa === null && shelfUploadData === null">
                  Please provide the planogram file to be used as the control
                  leg in this pack test.
                </p>
                <p v-else-if="shelfData.psa && shelfData.psa.success">
                  Read
                  <strong
                    >{{
                      shelfData.psa !== null
                        ? shelfData.psa.message.length
                        : "/"
                    }}
                    products</strong
                  >
                  from file named
                  <strong>{{ psaFile !== null ? psaFile.name : "/" }}</strong
                  >. <br />
                </p>
                <p v-else-if="shelfUploadData !== null">
                  Read
                  <strong
                    >{{
                      shelfUploadData && shelfUploadData.productsNumber
                    }}
                    products</strong
                  >
                  from file named
                  <strong>{{
                    shelfUploadData && shelfUploadData.customURLs[0].fileName
                  }}</strong
                  >. <br />
                </p>
                <p v-else>{{ shelfData.psa.message }}</p>
              </div>
            </div>
            <div
              class="shelf-upload__info-item shelf-upload__info-item--csv flex"
              :class="{
                'is-active':
                  (shelfData.csv !== null && shelfData.csv.success) ||
                  shelfUploadData !== null,
                error: shelfData.csv !== null && !shelfData.csv.success,
              }"
            >
              <Loader
                text="Uploading .csv file..."
                v-if="fileUploading.csv"
              ></Loader>
              <div class="shelf-upload__info-item-icon">
                <font-awesome-icon icon="check-circle" />
                <img src="/images/filesIcons/csv.svg" alt="" />
              </div>
              <div class="shelf-upload__info-item-content">
                <h3>2. Shelf pricing details (.csv)*</h3>
                <p v-if="shelfData.csv === null && shelfUploadData === null">
                  Please provide product pricing details in CSV format. <br />
                  <a
                    href="/csvTemplates/ShelfPricingTemplate.csv"
                    class="shelf-upload__info-item-link"
                    >[Download template]</a
                  >
                </p>
                <p v-else-if="shelfData.csv && shelfData.csv.success">
                  Read
                  <strong
                    >{{
                      shelfData.csv !== null
                        ? productsList.length - emptyBrandPriceProducts.length
                        : "/"
                    }}
                    products</strong
                  >
                  from file named
                  <strong>{{ csvFile !== null ? csvFile.name : "/" }}</strong
                  >. <br />
                </p>
                <p v-else-if="shelfUploadData !== null">
                  Read
                  <strong
                    >{{
                      shelfUploadData && shelfUploadData.productsNumber
                    }}
                    products</strong
                  >
                  from file named
                  <strong>{{
                    shelfUploadData && shelfUploadData.customURLs[1].fileName
                  }}</strong
                  >. <br />
                </p>
                <p v-else>{{ shelfData.csv.message }}</p>
                <div class="mt-5">
                  <h4>Select a price currency*</h4>
                  <a-select
                    style="width: 76px"
                    :dropdownMatchSelectWidth="false"
                    show-search
                    :default-value="currency || activeCurrency || '$'"
                    @change="setCurrency"
                  >
                    <a-select-option
                      v-for="item in currencySymbols"
                      :key="item.symbol"
                      :value="item.symbol"
                    >
                      <span v-html="item.symbol"></span>
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1 shelf-upload__right-col">
            <h2>Optional information</h2>
            <div
              class="shelf-upload__info-item shelf-upload__info-item--jpg flex"
              :class="{
                'is-active':
                  (shelfData.jpeg !== null && shelfData.jpeg.success) ||
                  (shelfUploadData !== null &&
                    Array.isArray(shelfUploadData.customURLs) &&
                    shelfUploadData.customURLs.some(
                      (obj) => obj.fileType === 'JPG'
                    )),
                error: shelfData.jpeg !== null && !shelfData.jpeg.success,
              }"
            >
              <Loader
                text="Uploading .jpg file..."
                v-if="fileUploading.jpeg"
              ></Loader>
              <div class="shelf-upload__info-item-icon">
                <font-awesome-icon icon="check-circle" />
                <img src="/images/filesIcons/jpg.svg" alt="" />
              </div>
              <div class="shelf-upload__info-item-content">
                <h3>High resolution shelf image (.jpg)</h3>
                <div
                  v-if="
                    shelfUploadData !== null &&
                    Array.isArray(shelfUploadData.customURLs) &&
                    shelfUploadData.customURLs.some(
                      (obj) => obj.fileType === 'JPG'
                    )
                  "
                >
                  <p class="cursor-pointer" @click="imagePreviewModal = true">
                    <u>Image preview</u>
                  </p>
                </div>
                <div v-else>
                  <p v-if="shelfData.jpeg === null">
                    If available, please upload a high resolution shelf image
                    which can be used in the study. <br />
                    Providing this reduces cost and timing.
                  </p>
                  <p v-else-if="shelfData.jpeg.success">
                    The image is successfully uploaded.
                  </p>
                  <p v-else>{{ shelfData.jpeg.message }}</p>
                </div>
              </div>
            </div>
            <div
              class="shelf-upload__info-item shelf-upload__info-item--zip flex"
              :class="{
                'is-active':
                  (shelfData.zip !== null && shelfData.zip.success) ||
                  (shelfUploadData !== null &&
                    Array.isArray(shelfUploadData.customURLs) &&
                    shelfUploadData.customURLs.some(
                      (obj) => obj.fileType === 'ZIP'
                    )),
                error: shelfData.zip !== null && !shelfData.zip.success,
              }"
            >
              <Loader
                text="Uploading .zip file..."
                v-if="fileUploading.zip"
              ></Loader>
              <span v-if="fileUploading.zip" class="animated-text font-size-13"
                >Please wait, this may take some time!</span
              >
              <div class="shelf-upload__info-item-icon">
                <font-awesome-icon icon="check-circle" />
                <img src="/images/filesIcons/zip.svg" alt="" />
              </div>
              <div class="shelf-upload__info-item-content">
                <h3>Individual product images (.zip)</h3>
                <p v-if="shelfData.zip === null">
                  Our methodology allows users to "pick up" products in a
                  virtual environment so individual product images are required.
                  You can zip and upload them or
                  <span
                    class="shelf-upload__info-item-link"
                    @click="scrollToChild"
                    >provide the network or Sharepoint location</span
                  >
                  where images can be found. <br />If available, these can
                  reduce cost and timing.
                </p>
                <p v-else-if="shelfData.zip.success">
                  The .zip file is successfully uploaded.
                </p>
                <p v-else>
                  {{ shelfData.zip.message }}
                </p>
              </div>
            </div>
            <div
              class="shelf-upload__info-item shelf-upload__info-item--url flex"
              :class="{
                'is-active': shelfData.url !== null && shelfData.url.success,
                error: shelfData.url !== null && !shelfData.url.success,
              }"
              v-show="insertImagesUrl"
              ref="child"
            >
              <Loader text="Sending URL..." v-if="fileUploading.url"></Loader>
              <div
                class="shelf-upload__info-item-delete"
                @click="insertImagesUrl = false"
              >
                <a-icon type="close" />
              </div>
              <div class="shelf-upload__info-item-icon">
                <font-awesome-icon icon="check-circle" />
                <img src="/images/filesIcons/url.svg" alt="" />
              </div>
              <div class="shelf-upload__info-item-content">
                <h3>Insert images location</h3>
                <div class="flex items-end">
                  <a-input placeholder="https://" v-model="imagesLocation" />
                  <a-button
                    type="primary"
                    :disabled="imagesLocation === ''"
                    @click="
                      submitFileUpload(
                        'url',
                        imagesLocation,
                        'UploadShelfImagesURL'
                      )
                    "
                    >Submit
                  </a-button>
                </div>
                <p
                  v-if="shelfData.url !== null && shelfData.url.success"
                  class="mb-0"
                >
                  The URL is successfully sent.
                </p>
                <p
                  v-if="shelfData.url !== null && !shelfData.url.success"
                  class="mb-0"
                >
                  {{ shelfData.url.message }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end stimuli-nav">
      <a-button
        type="primary"
        :disabled="
          (productsList.length === 0 && shelfUploadData === null) ||
          fileUploading.zip ||
          fileUploading.jpeg
        "
        @click="nextStep"
      >
        Next
        <icon-base
          class="ml-5"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#fff"
          ><icon-long-arrow-right /></icon-base
      ></a-button>
    </div>
    <PanzoomImagePreviewModal
      v-if="imagePreviewModal"
      :imageUrl="jpgFileURL"
      @close-modal="imagePreviewModal = false"
    ></PanzoomImagePreviewModal>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import Loader from "@/components/general/Loader.vue";
import PanzoomImagePreviewModal from "@/components/packTestSurvey/PanzoomImagePreviewModal.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import IconBase from "@/components/general/IconBase.vue";
import IconChartHistogram from "@/components/icons/IconChartHistogram.vue";
import IconLongArrowRight from "@/components/icons/IconLongArrowRight.vue";

export default {
  name: "ShelfUpload",
  components: {
    StimuliTitle,
    Loader,
    PanzoomImagePreviewModal,
    IconBase,
    IconChartHistogram,
    IconLongArrowRight,
  },
  props: {
    shelfUploadData: {
      type: Object,
    },
    currency: {
      type: String,
    },
  },
  data() {
    return {
      fileUploading: {
        csv: false,
        jpeg: false,
        zip: false,
        psa: false,
        url: false,
      },
      fileInfo: [],
      imagesLocation: "",
      insertImagesUrl: false,
      shelfData: {
        csv: null,
        jpeg: null,
        zip: null,
        psa: null,
        url: null,
      },
      productsList: [],
      emptyBrandPriceProducts: [],
      imagePreviewModal: false,
      currencySymbols: [
        { symbol: "$" },
        { symbol: "€" },
        { symbol: "£" },
        { symbol: "¥" },
        { symbol: "د.إ" },
        { symbol: "Af" },
        { symbol: "Lek" },
        { symbol: "դր." },
        { symbol: "ƒ" },
        { symbol: "Kz" },
        { symbol: "₼" },
        { symbol: "KM" },
        { symbol: "৳" },
        { symbol: ".د.ب" },
        { symbol: "FBu" },
        { symbol: "$b" },
        { symbol: "R$" },
        { symbol: "Nu." },
        { symbol: "P" },
        { symbol: "Br" },
        { symbol: "BZ$" },
        { symbol: "FC" },
        { symbol: "CHF" },
        { symbol: "UF" },
        { symbol: "₡" },
        { symbol: "Kč" },
        { symbol: "Fdj" },
        { symbol: "RD$" },
        { symbol: "د.ج" },
        { symbol: "₾" },
        { symbol: "₵" },
        { symbol: "D" },
        { symbol: "FG" },
        { symbol: "Q" },
        { symbol: "kn" },
        { symbol: "G" },
        { symbol: "Ft" },
        { symbol: "Rp" },
        { symbol: "₪" },
        { symbol: "₹" },
        { symbol: "ع.د" },
        { symbol: "J$" },
        { symbol: "JD" },
        { symbol: "KSh" },
        { symbol: "лв" },
        { symbol: "៛" },
        { symbol: "CF" },
        { symbol: "₩" },
        { symbol: "د.ك" },
        { symbol: "₸" },
        { symbol: "₭" },
        { symbol: "₨" },
        { symbol: "Lt" },
        { symbol: "Ls" },
        { symbol: "ل.د" },
        { symbol: "د.م." },
        { symbol: "Ar" },
        { symbol: "ден" },
        { symbol: "₮" },
        { symbol: "MOP$" },
        { symbol: "UM" },
        { symbol: "ރ." },
        { symbol: "MK" },
        { symbol: "RM" },
        { symbol: "MT" },
        { symbol: "₦" },
        { symbol: "C$" },
        { symbol: "﷼" },
        { symbol: "B/." },
        { symbol: "S/." },
        { symbol: "K" },
        { symbol: "₱" },
        { symbol: "zł" },
        { symbol: "Gs" },
        { symbol: "lei" },
        { symbol: "Дин." },
        { symbol: "₽" },
        { symbol: "FRw" },
        { symbol: "kr" },
        { symbol: "Le" },
        { symbol: "S" },
        { symbol: "Db" },
        { symbol: "฿" },
        { symbol: "TJS" },
        { symbol: "m" },
        { symbol: "د.ت" },
        { symbol: "T$" },
        { symbol: "₺" },
        { symbol: "L" },
        { symbol: "NT$" },
        { symbol: "TSh" },
        { symbol: "₴" },
        { symbol: "USh" },
        { symbol: "$U" },
        { symbol: "сўм" },
        { symbol: "Bs" },
        { symbol: "₫" },
        { symbol: "VT" },
        { symbol: "WS$" },
        { symbol: "FCFA" },
        { symbol: "SDR" },
        { symbol: "CFA" },
        { symbol: "F" },
        { symbol: "R" },
        { symbol: "ZK" },
        { symbol: "Z$" },
      ],
      activeCurrency: null,
      tempCurrency: null,
    };
  },
  computed: {
    psaFile() {
      return this.fileInfo.find((file) => file.name.endsWith(".psa")) || null;
    },
    csvFile() {
      return this.fileInfo.find((file) => file.type === "text/csv") || null;
    },
    nextButtonLabel() {
      return this.emptyBrandPriceProducts.length > 0
        ? "Add missing information"
        : "Select Test Products";
    },
    jpgFileURL() {
      const jpgObject = this.shelfUploadData.customURLs.find(
        (obj) => obj.fileType === "JPG"
      );
      if (jpgObject) {
        return jpgObject.fileURL;
      }
      return null;
    },
  },
  watch: {},
  methods: {
    setCurrency(e) {
      this.tempCurrency = e;
      if (this.shelfData.csv === null && this.shelfUploadData === null) {
        this.activeCurrency = e;
        this.$emit("active-currency", e);
      } else {
        this.$notification["error"]({
          message: "Update currency!",
          description: `If you want to save "${this.tempCurrency}" as a new currency, please re-upload your CSV file.`,
          placement: "bottomLeft",
          duration: 5,
        });
      }
    },
    reportUpload(info) {
      let fileType, wsName;

      if (
        info.file.type === "text/csv" &&
        this.shelfData.psa === null &&
        this.shelfUploadData === null
      ) {
        this.$notification["error"]({
          message: "Upload information!",
          description: "Please upload the .psa file first",
          placement: "bottomLeft",
          duration: 5,
        });
        return;
      }

      const index = this.fileInfo.findIndex(
        (file) => file.type === info.file.type
      );
      if (index >= 0) {
        this.fileInfo.splice(index, 1, info.file);
      } else {
        this.fileInfo.push(info.file);
      }

      switch (info.file.type) {
        case "text/csv":
          fileType = "csv";
          wsName = "UploadShelfCSV";
          break;
        case "image/jpeg":
          fileType = "jpeg";
          wsName = "UploadShelfJPG";
          break;
        case "application/zip":
          fileType = "zip";
          wsName = "UploadShelfZIP";
          break;
        default:
          if (info.file.name.endsWith(".psa")) {
            fileType = "psa";
            wsName = "UploadShelfPSA";
          }
      }

      if (wsName !== undefined) {
        this.submitFileUpload(fileType, info.file, wsName);
      }
    },
    submitFileUpload(type, file, wsName) {
      this.fileUploading[type] = true;
      let form = new FormData();
      form.append("surveyId", this.$route.params.id);
      if (type === "url") {
        form.append("ImagesURL", file);
      } else {
        form.append("file", file);
      }

      if (type === "csv") {
        form.append("currency", this.tempCurrency || this.currency || "$");
      }

      if (type === "zip") {
        this.$notification["info"]({
          message: "Uploading .zip file...",
          description: "This may take some time!",
          placement: "bottomLeft",
          duration: 6,
        });
      }

      const uploadShelfFiles = new Promise((resolve) => {
        wsUtils.UploadShelfFiles(
          {
            form,
            wsName,
          },
          resolve
        );
      });

      uploadShelfFiles.then((data) => {
        this.fileUploading[type] = false;
        this.shelfData[type] = data;
        this.checkShelfData();
        if (type === "csv") {
          this.activeCurrency = this.tempCurrency;
          this.$emit("active-currency", this.activeCurrency);
        }
      });
    },
    scrollToChild() {
      this.insertImagesUrl = true;
      setTimeout(() => {
        this.$emit("scroll-top", this.$refs.child.offsetTop);
      }, 0);
    },
    checkShelfData() {
      const { csv, psa, jpeg } = this.shelfData;
      if (csv?.message?.length && psa?.message?.length) {
        this.setProductstData();
      }
      if (jpeg?.success) {
        this.$emit("shelf-image", jpeg.imageURL);
      }
    },
    setProductstData() {
      this.shelfData.csv.message.forEach((item) => {
        if (item.Price === "" || item.Price === "0") {
          if (item.Price === "0") {
            item.Price = "";
          }
          this.emptyBrandPriceProducts.push(item);
        }
      });

      const uniqueBrands = this.shelfData.csv.message.reduce((unique, item) => {
        if (!unique.includes(item.Brand)) {
          unique.push(item.Brand);
        }
        return unique;
      }, []);

      uniqueBrands.sort();

      this.productsList = this.shelfData.csv.message;
      this.$emit("products-list", this.productsList);
      this.$emit("empty-brand-price-products", this.emptyBrandPriceProducts);
      this.$emit("unique-brands", uniqueBrands);
    },
    nextStep() {
      this.$emit("nextStep");
      if (this.productsList.length > 0) {
        this.$emit("products-list", this.productsList);
      }
    },
  },
  activated() {
    this.activeCurrency = null;
    this.tempCurrency = null;
  },
};
</script>

<style lang="scss" scoped>
.shelf-upload {
  padding: 33px 0 0;
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
  &__left {
    border-radius: 4px;
    width: 24%;
    max-width: 250px;
    @media screen and (max-width: 980px) {
      width: 100%;
      max-width: 100%;
    }
    ::v-deep .ant-upload-btn {
      padding: 10px;
      height: 367px;
      @media screen and (max-width: 980px) {
        height: 166px;
      }
      strong {
        display: block;
        font-size: 23px;
        color: #404040;
        line-height: 28px;
        margin-bottom: 4px;
      }
      span {
        font-size: 14px;
        line-height: 18px;
      }
      img {
        width: 44px;
        margin-bottom: 8px;
      }
    }
  }
  &__right {
    padding-left: 20px;
    @media screen and (max-width: 980px) {
      padding: 60px 0 0 0;
    }
    @media screen and (max-width: 800px) {
      flex-direction: column;
      padding-top: 32px;
    }
    h2 {
      font-size: 14px;
      text-transform: uppercase;
      margin-top: -28px;
      @media screen and (max-width: 800px) {
        margin-top: 0;
      }
    }
  }
  &__right-col {
    max-width: 520px;
  }
  &__form-info {
    p {
      margin-bottom: 0;
    }
    svg {
      color: #0fb0b1;
      width: 23px;
      height: 23px;
    }
  }
  &__info-item {
    display: flex;
    background: #fbf9f9;
    border-radius: 6px;
    padding: 8px;
    min-height: 180px;
    align-items: center;
    margin-bottom: 10px;
    transition: background 0.3s ease;
    position: relative;
    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
    &.is-active {
      background: #eef8fd;
      .shelf-upload__info-item-icon svg {
        opacity: 1;
        transform: scale(1);
      }
      .shelf-upload__info-item-icon img {
        opacity: 1;
      }
      .shelf-upload__info-item-content p {
        color: #0d215d;
      }
    }
    &.error {
      border: 1px solid red;
      background: #fffafa;
      .shelf-upload__info-item-icon img {
        opacity: 1;
      }
      .shelf-upload__info-item-content p {
        color: red;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    .loader {
      padding-top: 0px;
      padding-bottom: 0px;
      position: absolute;
      inset: 0;
      background: rgba(255, 255, 255, 0.93);
      z-index: 1;
      font-size: 13px;
      padding-top: 0px;
      padding-bottom: 0px;
      border: 1px solid #ededed;
      border-radius: 6px;
    }
    &--psa {
      &.is-active {
        .shelf-upload__info-item-icon svg {
          color: #5759e9;
        }
      }
    }
    &--csv {
      @media screen and (max-width: 800px) {
        margin-bottom: 25px !important;
      }
      &.is-active {
        .shelf-upload__info-item-icon svg {
          color: #02b94d;
        }
      }
    }
    &--jpg {
      &.is-active {
        .shelf-upload__info-item-icon svg {
          color: #015fad;
        }
      }
    }
    &--zip {
      &.is-active {
        .shelf-upload__info-item-icon svg {
          color: #ff3e4c;
        }
      }
    }
    &--url {
      min-height: auto;
      position: relative;
      border: 1px solid #f4f4f4;
      &:after {
        position: absolute;
        width: 1px;
        height: 10px;
        border-right: 1px solid #f4f4f4;
        content: "";
        left: 50%;
        bottom: 100%;
      }
      .shelf-upload__info-item-icon svg {
        color: #535454;
        left: -1px;
        top: 10px;
      }
      .shelf-upload__info-item-content h3 {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
  &__info-item-icon {
    width: 55px;
    min-width: 55px;
    margin-right: 10px;
    position: relative;
    img {
      max-width: 100%;
      opacity: 0.5;
    }
    svg {
      position: absolute;
      color: #5da6ca;
      left: -4px;
      top: -4px;
      opacity: 0;
      transition: all 0.3s ease;
      transform: scale(0.8);
    }
  }
  &__info-item-content {
    flex: 1;
    h3 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 3px;
      font-weight: 500;
    }
    h4 {
      font-size: 14px;
      margin-bottom: 2px;
    }
    p {
      font-size: 13px;
      color: #818181;
      line-height: 17px;
      margin-bottom: 5px;
    }
    input {
      height: 36px;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid #b9b9b9;
      background: transparent;
      padding: 0;
      margin-right: 5px;
      box-shadow: none;
      &:focus {
        border-color: #404040;
      }
    }
    .ant-btn {
      height: 28px;
      padding: 0 7px;
      font-size: 13px;
      margin-right: 5px;
    }
  }
  &__info-item-link {
    cursor: pointer;
    text-decoration: underline;
    color: #0d215d;
  }
  &__info-item-wrapper {
    border: 1px dashed #d0d0d0;
    border-radius: 6px;
    padding: 10px;
    margin-left: -10px;
  }
  &__info-item-or {
    position: relative;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    &:before {
      position: absolute;
      left: 0;
      width: 100%;
      top: 12px;
      content: "";
      border-bottom: 1px solid #f0f0f0;
    }
    span {
      background: #fff;
      z-index: 1;
      position: relative;
      padding: 0 9px;
      font-size: 14px;
    }
  }
  &__info-item-delete {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #a8a8a8;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    background: #fbf9f9;
    color: #5f5f5f;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: #f5f5f5;
    }
  }

  .animated-text {
    animation: fadeInAndOut 60s linear infinite;
    z-index: 1;
    overflow: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 80px;
    font-size: 12px;
  }

  @keyframes fadeInAndOut {
    0% {
      height: 0;
    }
    25% {
      height: auto;
    }
    75% {
      height: auto;
    }
    100% {
      height: 0;
    }
  }
}
</style>
