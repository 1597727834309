<template>
  <div
    class="product-item"
    :class="{
      'product-item--test': productInfo.FriendlyName !== 'Control',
      'product-item--no-image': productInfo.ImageURL === '',
    }"
  >
    <div class="product-item__inner">
      <div class="product-item__info-wrapper">
        <div
          class="product-item__image"
          :class="{
            'product-item__image--small':
              productInfo.FriendlyName === 'Control',
          }"
        >
          <ImageUpload
            :unEncryptSurveyId="unEncryptSurveyId"
            :image="productInfo.ImageURL"
            @image:url="setImageUrl"
          >
            <div class="product-item__image-upload-info">
              <span
                >Drag and Drop image here <br />
                Or</span
              >
              <div>Browse Files</div>
            </div></ImageUpload
          >
        </div>
        <div
          class="product-item__info"
          v-if="productInfo.FriendlyName === 'Control'"
        >
          <h3>{{ productName }}</h3>
          <span
            >Weight: <strong>{{ productInfo.Weight }}</strong></span
          >
          <span
            >Price: <strong>{{ productInfo.Price }}</strong></span
          >
        </div>
      </div>
      <div class="product-item__select">
        <div>
          <a-button type="primary" @click="showForm = true" class="w-full">
            <font-awesome-icon icon="pencil-alt" class="mr-5" />
            Edit {{ productInfo.FriendlyName }}</a-button
          >
        </div>
      </div>

      <a-modal
        title="Product details"
        :visible="showForm"
        @cancel="showForm = false"
        :footer="null"
        :width="350"
      >
        <div class="product-item__form">
          <a-row :gutter="16" class="mb-10">
            <a-col
              :span="24"
              class="product-item__form-item input-group input-group--small"
            >
              <label>Name*</label>
              <div class="position-r">
                <a-input
                  placeholder=""
                  v-model="productName"
                  @focus="$event.target.select()"
                />
              </div>
            </a-col>
          </a-row>
          <a-row
            :gutter="16"
            :class="{
              'mb-15': productInfo.FriendlyName === 'Control',
              'mb-25': productInfo.FriendlyName !== 'Control',
            }"
          >
            <a-col
              :xs="{ span: 12 }"
              class="product-item__form-item input-group input-group--small"
              ><label>Price*</label>
              <div class="position-r flex items-center">
                <span class="mr-5 currency dark-color">{{ currency }}</span>
                <a-input
                  placeholder=""
                  type="number"
                  v-model="price"
                  @focus="$event.target.select()"
                /></div
            ></a-col>
            <a-col
              :span="12"
              class="product-item__form-item input-group input-group--small"
              ><label>UPC*</label>
              <div class="position-r">
                <a-input
                  placeholder=""
                  v-model="upc"
                  @focus="$event.target.select()"
                />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col
              :span="24"
              v-if="productInfo.FriendlyName === 'Control'"
              class="product-item__form-item"
            >
              <p class="font-size-13">
                No need to make changes to the Control version of this product.
                Add a new version by clicking the Add button.
              </p>
            </a-col></a-row
          >

          <a-row :gutter="16">
            <a-col :span="24" class="product-item__form-item flex justify-end">
              <a-button type="primary" @click="showForm = false">
                Done</a-button
              >
            </a-col>
          </a-row>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import ImageUpload from "@/components/packTestSurvey/ImageUpload.vue";
export default {
  name: "EditProductItemNew",
  components: { ImageUpload },
  props: {
    productInfo: {
      type: Object,
    },
    unEncryptSurveyId: {
      type: Number,
    },
    index: {
      type: Number,
    },
    currency: {
      type: String,
    },
  },
  data() {
    return {
      showForm: false,
    };
  },
  computed: {
    price: {
      get() {
        return this.productInfo.Price;
      },
      set(price) {
        this.$emit("input", { ...this.productInfo, Price: price });
      },
    },
    productName: {
      get() {
        return this.productInfo.ProductName;
      },
      set(productName) {
        this.$emit("input", {
          ...this.productInfo,
          ProductName: productName,
        });
      },
    },
    upc: {
      get() {
        return this.productInfo.UPC;
      },
      set(upc) {
        this.$emit("input", {
          ...this.productInfo,
          UPC: upc,
        });
      },
    },
  },
  watch: {},
  methods: {
    setImageUrl(url) {
      this.$emit("input", { ...this.productInfo, ImageURL: url });
    },
    deleteItem() {
      let _this = this;
      this.$confirm({
        title: "Delete pack?",
        content: () => <div>Are you sure you want to delete?</div>,
        onCancel() {
          _this.$emit("delete");
        },
        okText: "No",
        cancelText: "Yes, delete",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  width: 310px;
  background: #fff;
  text-align: left;
  &--test {
    .product-item__image {
      border-style: dashed;
      height: 249px;
      &:hover {
        border-color: #fab3c7;
      }
      ::v-deep .image-upload {
        height: 100%;
        &__info {
          margin-top: 0;
        }
        .ant-upload {
          border: 0;
          border-radius: 10px;
          background: #fff;
        }

        &:after {
        }
      }
    }
    .product-item__info-wrapper {
      border: 0;
      padding: 0;
      margin-bottom: 0;
      box-shadow: none;
    }
  }
  &--no-image {
    .product-item__image {
      border-style: none;
      ::v-deep .image-upload {
        .ant-upload {
          background: #f4f4f4;
        }
      }
    }

    .product-item__image-upload-info {
      display: block;
    }
  }
  &__delete {
    font-size: 19px;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 2px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--red);
    transition: background 0.25s ease;
    &:hover {
      background: #f3f3f3;
    }
  }
  &__image-upload-info {
    color: var(--darkText);
    margin-top: 40px;
    display: none;
    span {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.28px;
      display: block;
      text-align: center;
      margin-bottom: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.28px;
      border-radius: 10px;
      border: 2px solid rgba(28, 28, 28, 0.3);
      padding: 8px;
      width: 110px;
      text-align: center;
      margin: 0 auto;
    }
  }
  &__inner {
    border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23fab3c7' stroke-width='4' stroke-dasharray='16%2c 28' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  &__image {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 96px;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;
    &--small {
      .product-item__image-upload-info {
        margin-top: 0;
        span {
          font-size: 12px;
          line-height: 17px;
        }
        div {
          font-size: 12px;
          line-height: 24px;
          padding: 2px;
          width: 92px;
        }
      }
      ::v-deep .image-upload {
        &:after {
          display: none;
        }
      }
    }
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      height: 90%;
      object-fit: contain;
    }
    ::v-deep .image-upload {
      min-width: 100%;
      height: 94px;
      &__info {
        margin-top: 0;
      }
      .ant-upload {
        border: 0;
      }
    }
  }
  &__info-wrapper {
    padding: 12px;
    border-radius: 10px;
    border: 1px solid var(--red);
    width: 100%;
    margin-bottom: 14px;
    position: relative;
    flex: 1;

    box-shadow: 0 0 0 3px var(--red);
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
    width: 100%;
    h3 {
      color: var(--dark);
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
      flex: 1;
      margin-bottom: 15px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.32px;
      strong {
        font-weight: 500;
      }
    }
  }
  &__select {
    padding: 0 10px;
    width: 100%;
    .ant-btn {
      min-width: 185px;
      border-radius: 5px;
      background: #a3aed0;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
    }
  }

  &__delete {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 12px;
    cursor: pointer;
  }

  &__form {
    flex: 1;
  }
  &__form-wrapper {
    padding: 27px 0 20px;
    border-bottom: 1px solid #e1e1e1;
    &:last-child {
      border-bottom: 0;
    }
  }
  &__form-item {
    label {
      color: #545454;
      margin-bottom: 1px;
      display: block;
      white-space: nowrap;
      font-size: 12px;
      text-align: left;
    }
    .ant-input {
      width: 100%;
      &[disabled] {
        background: #fff;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
    }

    textarea {
      resize: none;
    }
  }
  &__control-info {
    font-size: 11px;
    line-height: 14px;
  }
}
</style>
