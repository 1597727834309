<template>
  <div class="authoring-wrapper">
    <div class="flex h-full">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="surveyTypeID === 102 ? 'Reticle Alignment Test' : 'video'"
      />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <a-breadcrumb-item
            ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
          >
          <a-breadcrumb-item
            ><a href="/products">Products</a></a-breadcrumb-item
          >
          <a-breadcrumb-item>{{ $route.meta.surveyTitle }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div class="page-title">
            <icon-base
              :width="32"
              :height="33"
              :viewBox1="32"
              :viewBox2="33"
              iconName="adjustments"
              iconColor="transparent"
              ><icon-adjustments
            /></icon-base>
            <div class="page-title__text">
              <h1>General Settings</h1>
              <span>General Study information</span>
            </div>
          </div>
          <div class="box-wrapper box-wrapper--settings-form">
            <p class="font-size-14 mb-20">*All fields are required.</p>
            <div class="box-wrapper__inner">
              <div class="input-group">
                <label>
                  <span v-html="langObj['s1-studyname'].quiz"></span>
                  <a-tooltip placement="topRight">
                    <template slot="title">
                      <span v-html="langObj['s1-studyname'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>
                <div>
                  <a-popover title="" trigger="focus" placement="topRight">
                    <template slot="content">
                      <img
                        src="/images/icons/support.svg"
                        alt=""
                        class="tipIcon"
                      />
                      Give your study a meaningful name
                    </template>

                    <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                    <a-input
                      :placeholder="
                        $route.meta.surveyNamePlaceholder
                          ? $route.meta.surveyNamePlaceholder +
                            ' ' +
                            `${curDate}`
                          : `eg: YouTube Video Ads Test ${curDate}`
                      "
                      v-model="surveyName"
                    />
                  </a-popover>
                </div>
              </div>

              <div class="input-group">
                <label>
                  <span v-html="langObj['s1-brand'].quiz"></span>
                  <a-tooltip placement="topRight">
                    <template slot="title">
                      <span v-html="langObj['s1-brand'].tip"></span>
                    </template>
                    <a-icon type="info-circle" /> </a-tooltip
                ></label>
                <div>
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-input placeholder="eg: AAA" v-model="brandName" />
                </div>
              </div>

              <div class="input-group">
                <label>
                  <span v-html="langObj['s1-country'].quiz"></span>
                  <a-tooltip placement="topRight">
                    <template slot="title">
                      <span v-html="langObj['s1-country'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>
                <div>
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-select
                    class="fullWidth"
                    placeholder="Please select country"
                    option-filter-prop="children"
                    show-search
                    :filter-option="filterCountry"
                    :key="selectDropdownsKey"
                    :open="openCountry"
                    :default-value="
                      editableSurvey
                        ? editableSurvey.SurveyConfig.Country
                        : undefined
                    "
                    @change="selectCountry"
                    @focus="openDropdown"
                    @blur="openCountry = false"
                    @select="openCountry = false"
                  >
                    <a-select-option
                      v-for="market in markets"
                      :key="market.MarketId"
                      :value="market.MarketId"
                    >
                      {{ market.MarketName }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>

              <div class="input-group">
                <label>
                  <span v-html="langObj['s1-language'].quiz"></span>
                  <a-tooltip placement="topRight">
                    <template slot="title">
                      <span v-html="langObj['s1-language'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>
                <div>
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-select
                    @change="selectLang"
                    class="fullWidth"
                    placeholder="Please select country first to get related language list"
                    :default-value="
                      langs[langIndex]
                        ? langs[langIndex].LucidLanguageName
                        : undefined
                    "
                    :key="Math.random()"
                  >
                    <a-select-option
                      v-for="(lang, index) in langs"
                      :key="index"
                      :value="index"
                    >
                      {{ lang.LucidLanguageName }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>

              <div class="input-group">
                <label>
                  <span v-html="langObj['s1-category'].quiz"></span>
                  <a-tooltip placement="topRight">
                    <template slot="title">
                      <span v-html="langObj['s1-category'].tip"></span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </label>
                <div>
                  <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
                  <a-tree-select
                    v-model="categoryID"
                    class="fullWidth"
                    :replaceFields="{
                      children: 'Children',
                      title: 'CategoryName',
                      key: 'CategoryId',
                      value: 'CategoryId',
                    }"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :tree-data="categories"
                    :allowClear="true"
                    placeholder="Please select appropriate category"
                  >
                  </a-tree-select>
                </div>
              </div>
            </div>
          </div>
          <StepNaviBar
            :stepIndex="stepIndex"
            :nextDisable="nextDisable"
            :cancelButton="editableSurvey ? false : true"
            @nextHandler="nextStep"
            @cancel:draft="cancelDraft"
            nextBtnTooltip="Go to Creative"
            :stepsType="
              surveyTypeID === 102 ? 'Reticle Alignment Test' : 'video'
            "
          />
        </div>
      </section>
    </div>
    <CompletionProgress :checkList="quizs"></CompletionProgress>
  </div>
</template>

<script>
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import Skeleton from "@/components/general/Skeleton.vue";
import CompletionProgress from "@/components/general/CompletionProgress.vue";

import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import _ from "lodash";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import IconBase from "@/components/general/IconBase.vue";
import IconAdjustments from "@/components/icons/IconAdjustments.vue";

export default {
  name: "vid-setting",
  components: {
    ProgressBar,
    StepNaviBar,
    Skeleton,
    CompletionProgress,
    IconBase,
    IconAdjustments,
  },
  mixins: [surveyPriceMixin],
  props: {
    SurveyTypeId: Number,
  },
  data() {
    return {
      markets: [],
      langs: [],
      categories: [],
      curDate: "",
      categoryID: undefined,
      surveyName: "",
      brandName: "",
      countryID: undefined,
      langIndex: undefined,
      projectID: undefined,
      openCountry: false,
      langObj: {},
      client: {},
      stepIndex: 1,
      quizs: [],
      surveyID: 0,
      selectDropdownsKey: 0,
      editableSurvey: undefined,
      showSkeleton: false,
      brands: [],
      tempBrand: "",
      addBrandBtn: false,
      surveyTypeID: null,
      user: null,
    };
  },
  computed: {
    nextDisable() {
      return (
        this.surveyName === "" ||
        this.brandName === "" ||
        this.categoryID === undefined ||
        this.langIndex === undefined ||
        this.countryID === undefined
      );
    },
  },
  watch: {
    surveyName() {
      this.updateQuizStatus();
    },
    categoryID() {
      this.updateQuizStatus();
      this.getAudienceName();
    },
    langIndex() {
      this.updateQuizStatus();
    },
    countryID() {
      this.updateQuizStatus();
    },
    brandName() {
      this.updateQuizStatus();
    },
  },
  methods: {
    selectCountry(value) {
      this.countryID = parseInt(value, 10);
      this.langs = this.markets.find((market) => {
        return parseInt(market.MarketId, 10) === parseInt(value, 10);
      }).mLanguages;

      if (this.editableSurvey) {
        this.langIndex = _.findIndex(this.langs, (o) => {
          return (
            o.LucidLanguageId == this.editableSurvey.SurveyConfig.LucidLangId
          );
        });
      } else if (this.langs != undefined && this.langs.length > 0) {
        this.selectLang(0);
      }
    },
    filterCountry(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    openDropdown() {
      this.openCountry = true;
      setTimeout(() => {
        document.querySelector(".quizContent.country input").focus();
      }, 300);
    },
    selectLang(value) {
      this.langIndex = value;
    },
    nextStep() {
      this.getAudienceName();
      this.updateSurvey();
    },
    getAudienceName() {
      const findItemNested = (arr, itemId, nestingKey) =>
        arr.reduce((a, item) => {
          if (a) return a;
          if (item.CategoryId === itemId) return item;
          if (item[nestingKey])
            return findItemNested(item[nestingKey], itemId, nestingKey);
        }, null);

      const res = findItemNested(
        this.categories,
        Number(this.categoryID),
        "Children"
      );
      sessionStorage.setItem("audienceName", res ? res.AudienceName : null);
    },
    getCategoryInfoObject(object, string) {
      let result;
      if (!object || typeof object !== "object") return;
      Object.values(object).some((v) => {
        if (v === string) return (result = object);
        return (result = this.getCategoryInfoObject(v, string));
      });
      return result;
    },
    updateSurvey() {
      // we need function to update the survey.
      let surveyObj = {
        SurveyId: this.surveyID,
        SurveyName: this.surveyName,
        ProjectID: this.projectID,
        CountryId: this.countryID,
        CategoryId: this.categoryID,
        AlchemerLangId: this.langs[this.langIndex].AlchemerLanguageId,
        LucidLangId: this.langs[this.langIndex].LucidLanguageId,
        Brand: this.brandName,
        userId: this.user.userID,
      };

      const getCountryObject = this.markets.find(
        (element) => element.MarketId === this.countryID
      );

      let surveySummary = {
        country: getCountryObject.MarketName,
        projectName: this.surveyName,
        language: this.langs[this.langIndex].AlchemerLanguage,
        category: this.getCategoryInfoObject(this.categories, this.categoryID)
          .CategoryName,
        brand: this.brandName,
        sampleSize: this.sampleSize,
        audience: this.audience,
      };

      if (
        this.$route.name === "comms-setting" ||
        this.$route.name === "vid-tiktok-setting" ||
        this.$route.name === "vid-ua-setting" ||
        this.$route.name === "vid-yt-ua-setting" ||
        this.$route.name === "vid-reticle-setting"
      ) {
        surveySummary.sampleSize = 200;
        surveySummary.audience =
          this.$route.name === "vid-tiktok-setting"
            ? "Custom"
            : "General population";
      }

      if (this.$route.name === "comms-setting") {
        surveySummary.ReportOption =
          this.editableSurvey?.SurveyConfig?.ReportOption || "Automated";
      }

      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({ ...currentSummaryData, ...surveySummary });
      const stepSavePromise = new Promise((resolve) => {
        wsUtils.UpdateSurveyData1(surveyObj, resolve);
      });

      stepSavePromise.then(() => {
        jsUtils.setSurveysApiTrigger(true);
        this.$router.push({
          name: this.$route.meta.videoSettingsNextRoute || "vid-brand-strategy",
          params: { id: this.surveyID },
        });
      });
    },
    updateQuizStatus() {
      this.quizs = [
        {
          quizName: this.langObj["s1-studyname"].robot,
          completed: this.surveyName !== "",
        },
        {
          quizName: this.langObj["s1-brand"].robot,
          completed: this.brandName !== "",
        },
        {
          quizName: this.langObj["s1-country"].robot,
          completed: this.countryID !== undefined,
        },
        {
          quizName: this.langObj["s1-language"].robot,
          completed: this.langIndex !== undefined,
        },
        {
          quizName: this.langObj["s1-category"].robot,
          completed: this.categoryID !== undefined,
        },
      ];
    },
    setCurrentEditableSurveyData() {
      this.categoryID = this.editableSurvey.SurveyConfig.CategoryId;
      this.surveyName = this.editableSurvey.SurveyConfig.SurveyName;
      this.brandName = this.editableSurvey.SurveyConfig.BrandName;
      // this.langIndex = 0
      this.selectCountry(this.editableSurvey.SurveyConfig.CountryId);
      this.selectDropdownsKey = 1;
    },
    resetEditableSurveyData() {
      this.surveyName = "";
      this.brandName = "";
      this.langIndex = undefined;
      this.selectDropdownsKey = Math.random();
      this.countryID = undefined;
      this.categoryID = undefined;
      // this.markets = [];
      this.langs = [];
    },
    showSkeletonLoader() {
      if (this.editableSurvey !== undefined) {
        let _this = this;
        this.showSkeleton = true;
        setTimeout(function () {
          _this.showSkeleton = false;
        }, 900);
      }
    },
    cancelDraft() {
      this.$router.push("/");
    },
    brandSearch(value) {
      this.tempBrand = value;

      if (value !== "" && !this.brands.includes(value)) {
        this.addBrandBtn = true;
      } else {
        this.addBrandBtn = false;
      }

      if (value !== "") {
        const brandSearch = new Promise((resolve) => {
          wsUtils.BrandSearch(value, resolve);
        });
        brandSearch.then((data) => {
          _.forEach(data, (item) => {
            if (!this.brands.includes(item.Brand)) {
              this.brands.push(item.Brand);
            }
          });
        });
      }
    },
    addBrand() {
      if (this.tempBrand !== "" && !this.brands.includes(this.tempBrand)) {
        this.brands.push(this.tempBrand);
        this.brandName = this.tempBrand;
        this.selectDropdownsKey = Math.random();
        this.addBrandBtn = false;
      }
    },
    selectBrand(value) {
      this.brandName = value;
      this.selectDropdownsKey = Math.random();
      this.addBrandBtn = false;
    },
    deleteSurvey() {
      const surveyServicesPromise = new Promise((resolve) => {
        let data = {
          SurveyId: this.surveyID,
          userId: this.user.EncrypteduserID,
        };

        const surveyData = {
          endpoint: "DeleteSurvey",
          data,
        };
        wsUtils.surveyServices(surveyData, resolve);
      });
      surveyServicesPromise.then(() => {});
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );

    this.user = jsUtils.getUserInfo();
    const categoryPromise = new Promise((resolve) => {
      wsUtils.GetCategories(
        {
          userid: this.user.EncrypteduserID,
        },
        resolve
      );
    });

    categoryPromise.then((data) => {
      this.categories = data;
    });

    this.curDate = new Date().toISOString().slice(0, 10);

    this.updateQuizStatus();
  },
  beforeRouteLeave(to, from, next) {
    let editMode = this.editableSurvey !== undefined;
    const allowedRoutes = [
      "vid-brand-strategy",
      "vid-creative",
      "comms-creative",
      "vid-tiktok-creative",
      "vid-reticle-creative",
      "vid-yt-ua-creative",
      "vid-ua-creative",
      "login",
      "samlssos-singout",
    ];
    if (!allowedRoutes.includes(to.name) && !editMode) {
      let modalText = this.nextDisable
        ? `If you leave the page this draft will be discarded and you will lost progress.`
        : `If you leave the page this draft will be discarded and you will lost progress.. Please click on the 'Next' button to save it.`;
      let _this = this;
      this.$confirm({
        title: "Discard draft",
        content: () => <div>{modalText}</div>,
        okText: "Continue",
        cancelText: "Discard study",
        onCancel() {
          _this.deleteSurvey();
          next();
        },
      });
    } else {
      next();
    }
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
    this.debounceBrandSearch = _.debounce((value) => {
      this.brandSearch(value);
    }, 400);
  },
  activated() {
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.projectID = jsUtils.getWorkingProject();
    this.surveyID = this.$route.params.id;
    this.editableSurvey = jsUtils.getEditableSurvey();
    const countryPromise = new Promise((resolve) => {
      wsUtils.MarketList(resolve);
    });

    countryPromise.then((data) => {
      this.markets = data;
      if (this.editableSurvey) {
        this.setCurrentEditableSurveyData();
      } else {
        this.resetEditableSurveyData();
      }
    });

    this.showSkeletonLoader();

    const getCustomQuestionsPromise = new Promise((resolve) => {
      wsUtils.GetCustomQuestion(this.surveyID, resolve);
    });
    getCustomQuestionsPromise.then((data) => {
      let questionsType = data.CustomQuestions.map((item) => {
        return { QuestionType: item.questionType };
      });
      let surveyPriceSetData = {
        Question: questionsType,
      };
      let currentSurveyPriceData = jsUtils.getSurveyPrice();
      jsUtils.setSurveyPrice({
        ...currentSurveyPriceData,
        ...surveyPriceSetData,
      });
      this.getSurveyTotalPrice();
    });
  },
};
</script>

<style lang="scss" scoped>
.videoAds {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;
}

.authoring {
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
}

.questions {
  min-width: 600px;
}

.quiz {
  .btn-add-brand {
    height: 24px;
    font-size: 13px;
    padding: 0 6px;
    position: absolute;
    top: 37px;
    right: 34px;
    z-index: 1;
  }
}
</style>
